import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button'

import notificationContext from '../../context/NotificationContext';

import axios from 'axios'
import CreateUserModalComponent from './CreateUserModalComponent';
import DeleteConfirmationModalComponent from './DeleteConfirmationModalComponent';

function UserCardComponent(props) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const { showAlert } = useContext(notificationContext)
  let { practice, userid } = useParams();
  const [data, setData] = useState()
  const [loadState, setLoadState] = useState(false)


  const updateTable = (res) => {
    setData(res)
  }

  const handleDelete = () => {
    axios.delete(`${API_HOST}/users/delete/${userid}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          navigate(`/practice/${practice}/home`)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      })
      .catch(error => {
      })
  };

  const sendPasswordReset = () => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: `${API_HOST}/forgot-password/${data.email}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.request(config).then(res => {
      if (res.data.success) {
        showAlert({
          variant: 'success',
          message: res.data.message
        })
      } else {
        showAlert({
          variant: res.data.variant,
          message: res.data.message
        })
      }
    })
  };


  useEffect(() => {
    axios.get(`${API_HOST}/users/practice/${practice}/user/${userid}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data)
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      }).catch(error => {
        showAlert({
          variant: "danger",
          message: "An unknown error has occured."
        })
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className="card p-3 mb-5 mt-3">
              <div className="container p-3 card-body">
                <div className='pb-3'>
                  <Button variant="primary" href={'/practice/' + practice + '/home?accordion_tab=2'}>
                    Back
                  </Button>
                </div>
                <h5 className="card-title pb-2">
                  <span>{data.name}</span>
                  <span>
                    <Button variant="secondary float-end" onClick={sendPasswordReset}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                      </svg>
                      Send Password Reset Email
                    </Button>
                  </span>
                </h5>
                
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Created: </span>{new Date((String(data.createdAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Updated: </span>{new Date((String(data.updatedAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Name: </span>{data.name}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Email: </span>{data.email}</span>
                  </div>
                  <div className="col-6 p-2">
                    <p><span className="fw-bold">Status: </span>
                      {(() => {
                        if (data.active) {
                          return (
                            <>
                              <span style={{ color: 'green' }}>● </span>
                              Active
                            </>
                          )
                        } else {
                          return (
                            <>
                              <span style={{ color: 'red' }}>● </span>
                              Inactive
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Role: </span>{data.Role.name}</span>
                  </div>
                </div>
                <div className="row pt-3 d-flex">
                  <div className="p-2 d-flex justify-content-end">
                    <CreateUserModalComponent userid={data.id} updateTable={updateTable} />
                    <DeleteConfirmationModalComponent id={data.id} objectText={"User"} object={"users"} objectName={data.name}/>
                  </div>
                </div>
              </div>
            </div>

          )
        }
      })()}
    </>
  )
}

export default UserCardComponent