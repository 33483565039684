import React, { useEffect, useState, useContext } from 'react'
import { Outlet, Navigate } from 'react-router'
import { AuthContext } from '../helper/AuthContext'
import axios from 'axios'

function AuthenticatedRoutes() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { authState, setAuthState } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    if (authState) {
      setAuthorized(true)
      setLoading(true)
    } else {
      axios.post(`${API_HOST}/users/auth/refresh`, {}, {
        withCredentials: true,
      })
        .then(res => {
          if (!res.data.error) {
            if (res.data.success && res.data.data) {
              setAuthState(res.data.data).then(() => {
                setAuthorized(true)
                setLoading(true)
              })
            } else {
              setLoading(true)
            }
          } else {
            setLoading(true)
          }
        })
        .catch(function (error) {
          setLoading(true)
        })
    }
  }, [authState])


  return (
    <>
      {(() => {
        if (loading) {
          return (
            authState ? <Outlet /> : <Navigate to="/403" />
          )
        }
      })()}
    </>
  )
}

export default AuthenticatedRoutes