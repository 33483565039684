import React, { useState } from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';
import UserCardComponent from '../../components/components/UserCardComponent';
import FooterComponent from '../../components/nav/FooterComponent';

function Settings() {
    let { practice } = useParams();
    return (
        <div className='container shadow p-3 mb-4 bg-white rounded mt-3'>
            <UserCardComponent />
            <FooterComponent />
        </div>

    );
}

export default Settings
