import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../helper/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Select from 'react-select'

import axios from 'axios'
import notificationContext from '../../context/NotificationContext';

function CreateUserModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    const { authState } = useContext(AuthContext)
    const {showAlert} = useContext(notificationContext)
    const [loadState, setLoadState] = useState(false)
    const [isValid, setValid] = useState(true);
    const [roles, setRoles] = useState([]);
    const [practices, setPractices] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setPractices([])
        setRoles([])
        axios.get(`${API_HOST}/roles`, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    res.data.data.forEach(element => {
                        const flattenedData = {
                            "label": element.name,
                            "value": element.id,
                            "level": element.level,
                        }
                        if (element.level > 3) {
                            setRoles(current => [flattenedData, ...current])
                        }
                    })
                    axios.get(`${API_HOST}/practices`, { withCredentials: true })
                        .then(res => {
                            if (res.data.success) {
                                res.data.data.forEach(element => {
                                    const flattenedData = {
                                        "label": element.name,
                                        "value": element.slug,
                                    }

                                    setPractices(current => [flattenedData, ...current])

                                })
                                setShow(true)
                            }
                            if (res.data.alert) {
                                showAlert({
                                    variant: res.data.variant,
                                    message: res.data.message
                                })
                            }
                        })
                        .catch(error => {
                            showAlert({
                                variant: "danger",
                                message: "An unknown error has occured."
                            })
                        })
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
            })
            .catch(error => {
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })
        
    }
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        practice: '',
    })

    const roleChange = (event) => {
        setForm({
            ...form,
            role: event
        })
    }

    const practiceChange = (event) => {
        setForm({
            ...form,
            practice: event
        })
    }

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValid(false)
        var practice = null
        if (props.practice) {
            practice = props.practice
        } else {
            practice = form.practice.value
        }
        if (form.role.level === 5) {
            practice = null
        }
        axios.post(`${API_HOST}/users/create`, {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            role: form.role.value,
            role_level: form.role.level,
            practice: practice,
        }, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    setShow(false)
                    setValid(true)
                    if (props.updateTable) {
                        props.updateTable(res.data.data)
                    }
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
                setShow(false)
                setValid(true)
            })
            .catch(error => {
                setShow(false)
                setValid(true)
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })
    }

    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            <Button variant="primary" onClick={handleShow}>
                                Create User
                            </Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Create User</Modal.Title>
                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-6'>
                                                    <h6>First Name</h6>
                                                    <Form.Control id="create-staff-first-name-input" className="form-control" type="text" name="first_name" placeholder="First Name" onChange={updateForm} required />
                                                </Form.Group>
                                                <Form.Group className='col-6'>
                                                    <h6>Last Name</h6>
                                                    <Form.Control id="create-staff-last-name-input" className="form-control" type="text" name="last_name" placeholder="Last Name" onChange={updateForm} required />
                                                </Form.Group>
                                            </div>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-12'>
                                                    <h6>Role</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Role"
                                                        closeMenuOnSelect={true}
                                                        options={roles}
                                                        onChange={roleChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                                {(() => {
                                                    if (!props.practice) {
                                                        return (
                                                            <div className='row pt-2'>
                                                                <Form.Group className='col-12'>
                                                                    <h6>Practice</h6>
                                                                    <Select
                                                                        styles={{
                                                                            // Fixes the overlapping problem of the component
                                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                                        }}
                                                                        placeholder="Practice"
                                                                        closeMenuOnSelect={true}
                                                                        options={practices}
                                                                        onChange={practiceChange}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <Form.Group className='col-12 pt-2'>
                                                    <h6>Email</h6>
                                                    <Form.Control id="create-staff-email-input" className="form-control" type="text" name="email" placeholder="Email" onChange={updateForm} required />
                                                </Form.Group>
                                            
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type='submit' disabled={!isValid}>
                                            SAVE
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default CreateUserModalComponent