import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import { AuthContext } from '../../helper/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import DatePicker from "react-datepicker";

import notificationContext from '../../context/NotificationContext';
import axios from 'axios'

function CreatePatientModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    const navigate = useNavigate()
    let { practice } = useParams();
    const { showAlert } = useContext(notificationContext)
    const { authState } = useContext(AuthContext)
    const [isValid, setValid] = useState(true);
    const [loadState, setLoadState] = useState(false)
    const [show, setShow] = useState(false);
    const [defaultGender, setDefaultGender] = useState({});
    const handleClose = () => setShow(false);
    const genders = [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
        { label: "Prefer Not to Say", value: "not provided" }
    ]
    const handleShow = () => {
        if (props.patientid && practice) {
            axios.get(`${API_HOST}/patients/practice/${practice}/${props.patientid}`, { withCredentials: true })
                .then(res => {
                    if (res.data.success) {
                        setForm({
                            ...form,
                            id: props.patientid,
                            first_name: res.data.data.first_name,
                            last_name: res.data.data.last_name,
                            email: res.data.data.email,
                            gender: res.data.data.gender,
                            dob: new Date(res.data.data.dob),
                            active: res.data.data.active
                        })
                        if (res.data.data.gender === "male") {
                            setDefaultGender(genders[1])
                        }
                        if (res.data.data.gender === "female") {
                            setDefaultGender(genders[0])
                        }
                        if (res.data.data.gender === "not provided") {
                            setDefaultGender(genders[2])
                        }
                        setShow(true);
                    }
                    if (res.data.alert) {
                        showAlert({
                          variant: res.data.variant,
                          message: res.data.message
                        })
                      }
                }).catch(error => {
                    showAlert({
                        variant: "danger",
                        message: "An unknown error has occured."
                    })
                })
        } else {
            setShow(true);
        }

    }

    const updateSwitchForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const [form, setForm] = useState({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        gender: '',
        practice: practice,
        active: true
    })

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const genderChange = (event) => {
        setForm({
            ...form,
            gender: event.value
        })
    }

    const updateBirthDate = (e) => {
        setForm({
            ...form,
            dob: e
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValid(false)
        var valid = true
        var emailPhone = form.email.trim()
        if (!emailPhone.includes("@")) {
            emailPhone = emailPhone.replace(/\D/g, '').trim();
        }
        if (valid) {
            axios.post(`${API_HOST}/patients`, {
                id: form.id,
                first_name: form.first_name.trim(),
                last_name: form.last_name.trim(),
                email: emailPhone,
                gender: form.gender.trim(),
                dob: form.dob,
                active: form.active,
                practice: form.practice
            }, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    props.updateTable(res.data.data)
                    if (!props.patientid) {
                        navigate(`/practice/${res.data.data.PracticeId}/patients/${res.data.data.id}`)
                    }
                }
                if (res.data.alert) {
                    showAlert({
                    variant: res.data.variant,
                    message: res.data.message
                    })
                }
                setForm({
                    ...form,
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    dob: '',
                    gender: '',
                    practice: practice,
                    active: true
                })
                setValid(true)
                setShow(false)

            })
            .catch(error => {
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
                setValid(true)
                setShow(false)
            })
        } else {
            showAlert({
                variant: 'danger',
                message: 'You have not entered a valid 10 digit phone number or email.'
            })
            setValid(true)
            setShow(false)
        }
    }

    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            {(() => {
                                if (props.patientid) {
                                    return (
                                        <Button variant="success" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            EDIT PATIENT
                                        </Button>
                                    )
                                } else {
                                    return (
                                        <Button variant="primary" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            CREATE PATIENT
                                        </Button>
                                    )
                                }
                            })()}

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {(() => {
                                            if (props.patientid) {
                                                return (
                                                    <>EDIT PATIENT</>
                                                )
                                            } else {
                                                return (
                                                    <>CREATE PATIENT</>
                                                )
                                            }
                                        })()}
                                    </Modal.Title>
                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-6'>
                                                    <h6>First Name</h6>
                                                    <Form.Control id="create-staff-first-name-input" className="form-control" type="text" name="first_name" placeholder="First Name" value={form.first_name} onChange={updateForm} required />
                                                </Form.Group>
                                                <Form.Group className='col-6'>
                                                    <h6>Last Name</h6>
                                                    <Form.Control id="create-staff-last-name-input" className="form-control" type="text" name="last_name" placeholder="Last Name" value={form.last_name} onChange={updateForm} required />
                                                </Form.Group>
                                            </div>
                                            {/* this is inline

                                            DOB AND GENDER

                                            <div className='row pt-2'>
                                                <Form.Group className='col-6'>
                                                    <h6>Gender</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Gender"
                                                        closeMenuOnSelect={true}
                                                        options={genders}
                                                        onChange={genderChange}
                                                        defaultValue={defaultGender}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group className='col-6 datePickerCustomFormatting'>
                                                    <h6>DOB</h6>
                                                    <DatePicker id="dob" className="form-control" selected={form.dob} onChange={updateBirthDate} required />
                                                </Form.Group>

                                            </div>

                                            */}
                                            {(() => {
                                                if (!props.patientid) {
                                                    return (
                                                        <div className='row pt-2'>
                                                            <Form.Group className='col-12'>
                                                                <h6>Phone Number or Email</h6>
                                                                <Form.Control id="create-staff-email-input" className="form-control" type="text" name="email" placeholder="Phone/Email" value={form.email} onChange={updateForm} required />
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className='row pt-2'>
                                                            <Form.Group className='col-12'>
                                                                <h6>Phone Number or Email</h6>
                                                                <Form.Control id="create-staff-email-input" className="form-control" type="text" name="email" placeholder="Phone/Email" value={form.email} onChange={updateForm} required />
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            <Form.Group className='row p-2'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchActive" name="active" value={form.active} onChange={updateSwitchForm} defaultChecked={form.active} />
                                                    <label className="form-check-label" htmlFor="switchActive">Status</label>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                        {(() => {
                                            if (props.patientid) {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>
                                                        SAVE
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>
                                                        SAVE
                                                    </Button>
                                                )
                                            }
                                        })()}
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default CreatePatientModalComponent