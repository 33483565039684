import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import axios from 'axios';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { AuthContext } from '../../helper/AuthContext'

function SiteAdminNavbarComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { authState, setAuthState } = useContext(AuthContext)
  const [loadState, setLoadState] = useState(false)
  const navigate = useNavigate()
  const pathname = window.location.pathname
  
  const logout = () => {
    axios.post(`${API_HOST}/users/logout`, {}, {
      withCredentials: true,
    })
      .then(res => {
        if (res.data.error) {
          setAuthState(undefined)
        } else {
          setAuthState(undefined)
          navigate("/")
        }
      })
      .catch(error => {
        setAuthState(undefined)
      })
  }

  useEffect(() => {
    setLoadState(true)
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <Navbar bg="light" expand="lg" className="navbar-light shadow pb-3">
              <div className="container">
                <Navbar.Brand href="/">
                  <img
                    src="/logo.png"
                    className="d-inline-block align-top nav-bar-logo-custom"
                    alt="Logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="navbar-nav nav-fill w-100 mb-lg-0 nav-link" activeKey={pathname}>
                    <Nav.Link href={`/admin/`}>Home</Nav.Link>
                    {(() => {
                      if (!authState) {
                        return (
                          <Nav.Link className='fw-bold' style={{"font-size": '20px'}} href="/login/">Login</Nav.Link>
                        )
                      } else {
                        return (
                          <Nav.Link className='fw-bold' style={{"font-size": '20px'}} onClick={logout} >Logout</Nav.Link>
                        )
                      }
                    })()}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          )
        }
      })()}
    </>
  )
}

export default SiteAdminNavbarComponent