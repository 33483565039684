import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import axios from 'axios';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


import { AuthContext } from '../../helper/AuthContext'

function NavbarComponent(practice) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { authState, setAuthState } = useContext(AuthContext)
  const [practiceLogo, setPracticeLogo] = useState('default.jpg')
  const [loadState, setLoadState] = useState(false)
  const navigate = useNavigate()
  const pathname = window.location.pathname

  const logout = () => {
    axios.post(`${API_HOST}/users/logout`, {}, {
      withCredentials: true,
    })
      .then(res => {
        if (res.data.error) {
          setAuthState(undefined)
        } else {
          setAuthState(undefined)
          navigate("/")
        }
      })
      .catch(error => {
        setAuthState(undefined)
      })
  }

  useEffect(() => {
    axios.get(`${API_HOST}/practices/${practice.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          if (res.data.data) {
            setPracticeLogo(res.data.data.logo)
            setLoadState(true)
          }
        }
      })

  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
              <div className="container">
                <Navbar.Brand href={`/practice/${practice.practice}/checkin`} style={{"cursor": "default"}}>
                  <img
                    src={API_HOST + "/images/logos/" + practiceLogo}
                    className="d-inline-block align-top nav-bar-logo-custom"
                    alt="Logo"
                  />
                </Navbar.Brand>
                <Nav.Item className="float-end transparent-nav-item">
                    <Nav.Link onClick={logout} className='transparent-nav-item' style={{"cursor": "pointer"}}>Logout</Nav.Link>
                </Nav.Item>
              </div>
          )
        }
      })()}
    </>
  )
}

export default NavbarComponent