import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import notificationContext from '../../context/NotificationContext';
import axios from 'axios'

function CreateSiteModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    const {showAlert} = useContext(notificationContext)
    const [loadState, setLoadState] = useState(false)
    const [isValid, setValid] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [preview, setPreview] = useState(`${API_HOST}/images/logos/default.jpg`)
    const [form, setForm] = useState({
        name: '',
        slug: '',
        active: true,
        logo: '',
        logoFileName: '',
    })

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateSwitchForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const fileSelectedHandler = (e) => {
        setForm({
            ...form,
            logo: e.target.files[0],
            logoFileName: document.getElementById('create-site-logo-input').value,
        })
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValid(false)
        let formData = new FormData();
        formData.append('name', form.name);
        formData.append('slug', form.slug);
        formData.append('logo', form.logo);
        formData.append('active', form.active);
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
            withCredentials: true
        }

        axios.post(`${API_HOST}/practices`, formData, config)
            .then(res => {
                if (res.data.success) {
                    props.updateTable(res.data.data)
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
                setForm({
                    ...form,
                    name: '',
                    slug: '',
                    active: true,
                    logo: '',
                    logoFileName: '',
                })
                setShow(false)
                setValid(true)
            })
            .catch(error => {
                console.error(error)
                setShow(false)
                setValid(true)
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })

    }

    useEffect(() => {
        if (props.practice) {
            axios.get(`${API_HOST}/practices/${props.practice}`, { withCredentials: true })
                .then(res => {
                    if (res.data.success) {
                        setForm({
                            ...form,
                            name: res.data.data.name,
                            slug: res.data.data.slug,
                            active: res.data.data.active,
                            logo: res.data.data.logo,
                        })
                        setPreview(API_HOST + "/images/logos/" + res.data.data.logo)
                        setLoadState(true)
                    }
                })
        } else {
            setPreview(API_HOST + "/images/logos/default.jpg")
            setLoadState(true)
        }
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            <Button variant="primary" onClick={handleShow}>
                                {(() => {
                                    if (props.practice) {
                                        return (
                                            <>Edit Practice</>
                                        )
                                    } else {
                                        return (
                                            <>Create Practice</>
                                        )
                                    }
                                })()}

                            </Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title> {(() => {
                                        if (props.practice) {
                                            return (
                                                <>Edit Practice</>
                                            )
                                        } else {
                                            return (
                                                <>Create Practice</>
                                            )
                                        }
                                    })()}</Modal.Title>
                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body>
                                        <div className='container'>
                                            <Form.Group className='row pt-2'>
                                                <h6>Site Name</h6>
                                                <Form.Control id="create-site-name-input" className="form-control" type="text" name="name" placeholder="Site Name" value={form.name} onChange={updateForm} required />
                                            </Form.Group>
                                            {preview &&
                                                <div className='row pt-2'>
                                                    <img className="pb-2 img-thumbnail w-100" src={preview} alt="Thumbnail preview" />
                                                </div>
                                            }
                                            <Form.Group className='row pt-2'>
                                                <h6>Site Logo</h6>
                                                <Form.Control id="create-site-logo-input" className="form-control" type="file" name="logo" accept=".jpg,.gif,.png" onChange={fileSelectedHandler} />
                                            </Form.Group>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        {(() => {
                                            if (props.practice) {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>SAVE</Button>
                                                )
                                            } else {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>SAVE</Button>
                                                )
                                            }
                                        })()}
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default CreateSiteModalComponent