import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import './errors.css'; // Tell webpack that Button.js uses these styles

function Error403() {

	const navigate = useNavigate()

    useEffect(() => {
        navigate("/login/")
    });

	return (
		<>	
			{/* 
				<div id="notfound">
					<div className="notfound">
						<div className="notfound-404">
							<h1>Oops!</h1>
							<h2>403 - Access Forbidden</h2>
						</div>
						<a href="/">Return to home page</a>
					</div>
				</div>
			*/}
		</>

	);
}

export default Error403
