import React, { useMemo, useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//SWAP https://www.npmjs.com/package/material-react-table
import CreateCaseModalComponent from './CreateCaseModalComponent';

import { AuthContext } from '../../helper/AuthContext'

import axios from 'axios'

function CaseTableComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  let { practice, patientid } = useParams();
  const navigate = useNavigate()
  const { authState } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [loadState, setLoadState] = useState(false)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15, //set different default page size by initializing the state here
  });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([{ id: 'patient', desc: false }]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'caseId',
        header: 'Case ID',
        filterVariant: 'text', // default
      }, {
        accessorKey: "patient",
        header: "Patient",
        filterVariant: 'text',
      }, {
        accessorKey: "email",
        header: "Email/Phone",
        filterVariant: 'text'
      }, {
        accessorKey: "evaluationDate",
        header: "Evaluation",
        filterVariant: 'date-range',
      }, {
        accessorKey: "dischargeDate",
        header: "Discharge",
        filterVariant: 'date-range',
      }, {
        accessorKey: "fsr",
        header: "SAS",
        size: 120,
        Cell: ({ cell }) => {
          if (cell.row.original.fsr) {
            return (
              <div className='text-nowrap'>
                Yes
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                No
              </div>
            )
          }
        }
      }, {
        accessorKey: "grb",
        header: "ROC",
        size: 120,
        Cell: ({ cell }) => {
          if (cell.row.original.grb) {
            return (
              <div className='text-nowrap'>
                Yes
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                No
              </div>
            )
          }
        }
      }, {
        accessorKey: "nps",
        header: "NPS",
        size: 120,
        Cell: ({ cell }) => {
          if (cell.row.original.nps) {
            return (
              <div className='text-nowrap'>
                Yes
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                No
              </div>
            )
          }
        }
      }, {
        accessorKey: "active",
        header: "Status",
        Cell: ({ cell }) => {
          if (cell.row.original.active) {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'green' }}>● </span>
                Active
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'red' }}>● </span>
                Inactive
              </div>
            )
          }
        },
      }
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd', //instead of the default "onChange" mode
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/practice/${practice}/cases/${row.original.id}`)
        //console.info(JSON.stringify(row.original.id));
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    state: { pagination, rowSelection, sorting }, //must pass states back down if using their on[StateName]Change callbacks
  });

  const updateTable = (res) => {
    var evaluationDate = res.evaluationDate
    var dischargeDate = res.dischargeDate
    if (evaluationDate) {
      evaluationDate = new Date((String(evaluationDate))).toDateString()
    }
    if (dischargeDate) {
      dischargeDate = new Date((String(dischargeDate))).toDateString()
    }
    const flattenedData = {
      "id": res.id,
      "patient": res.Patient.name,
      "email": res.Patient.email,
      "caseId": res.case_nbr,
      "evaluationDate": evaluationDate,
      "dischargeDate": dischargeDate,
      "fsr": res.fsr,
      "grb": res.grb,
      "nps": res.nps,
      "active": res.active,
    }
    var temp = []
    temp.push(flattenedData)
    for (var i = 0; i < data.length; i++) {
      var tempEntry = data[i]
      if (tempEntry.email == flattenedData.email) {
        tempEntry.active = false
      }
      if (tempEntry.id != res.id) {
        temp.push(tempEntry)
      }
    }
    setData(temp)
  };


  useEffect(() => {
    var url = `${API_HOST}/cases/practice/${practice}`
    if (patientid) {
      url = `${API_HOST}/cases/practice/${practice}/patient/${patientid}`
    }
    axios.get(url, { withCredentials: true })
      .then(res => {
        if (res.data.success) {

          res.data.data.forEach(element => {
            var evaluationDate = element.evaluationDate
            var dischargeDate = element.dischargeDate
            if (evaluationDate) {
              evaluationDate = new Date((String(evaluationDate))).toDateString()
            }
            if (dischargeDate) {
              dischargeDate = new Date((String(dischargeDate))).toDateString()
            }
            const flattenedData = {
              "id": element.id,
              "patient": element.Patient.name,
              "email": element.Patient.email,
              "caseId": element.case_nbr,
              "evaluationDate": evaluationDate,
              "dischargeDate": dischargeDate,
              "fsr": element.fsr,
              "grb": element.grb,
              "nps": element.nps,
              "active": element.active,
            }
            setData(current => [flattenedData, ...current])
          })

          setLoadState(true)
        }
      }).catch(error => {
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container shadow p-3 mb-5 bg-white rounded mt-3'>
              <div className='row justify-content-between'>
                <span className='d-flex justify-content-between'>
                  <h2>Cases</h2>
                  {(() => {
                    if (authState.Role.level > 1) {
                      return (
                        <CreateCaseModalComponent updateTable={updateTable} />
                      )
                    }
                  })()}
                </span>
              </div>
              <div className='row m-2'>
                <hr />
              </div>

              <div className='row'>

                <div className='col-12 overflow-scroll'>
                  <MaterialReactTable table={table} />
                </div>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default CaseTableComponent