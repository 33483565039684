import React, { useContext } from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import PatientCardComponent from '../../components/components/PatientCardComponent';
import CaseTableComponent from '../../components/components/CaseTableComponent';

import FooterComponent from '../../components/nav/FooterComponent';

import { AuthContext } from '../../helper/AuthContext'

function Patient() {
    let { practice } = useParams();
    const { authState } = useContext(AuthContext)
    return (
        <div>
            <div className='container'>
                <PatientCardComponent />
            </div>
            {(() => {
                if (authState.Role.level > 0) {
                    return (
                        <div className='container'>
                            <CaseTableComponent />
                        </div>
                    )
                }
            })()}
            <FooterComponent />

            
        </div>

    );
}

export default Patient
