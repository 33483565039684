import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../../components/nav/PracticeNavbarComponent';

import Spinner from 'react-bootstrap/Spinner';

function ThankYouForCheckingIn() {
    let { practice } = useParams();
    const navigate = useNavigate()
    const [timeLeft, setTimeLeft] = useState(2);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft === 0) {
                navigate(`/practice/${practice}/checkin`)
            } else {
                setTimeLeft(timeLeft - 1);
            }
        }, 1000);

    });

    return (
        <>
            <div id='checkin-thankyou' className='grey-background-100'>
                
                <div className="container p-3">
                    <div className="container p-3">
                        <div className="row">
                            <div className="container">
                                <div className="card dynamic-card-resize shadow-lg position-absolute top-50 start-50 translate-middle">
                                    <div className="container p-3 card-body">
                                        <div className="row d-flex flex-row justify-content-center">
                                            <h1 className='display-4 fw-bold text-primary text-center pt-0'>THANK YOU!</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ThankYouForCheckingIn
