import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

import FooterComponent from '../../components/nav/FooterComponent';

function Help() {
    const API_HOST = process.env.REACT_APP_API_HOST
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultAccordionActiveKey, setDefaultAccordionActiveKey] = useState("overview")
    const [loadState, setLoadState] = useState(false)

    useEffect(() => {
        if (searchParams.get("accordion_tab")) {
            setDefaultAccordionActiveKey(searchParams.get("accordion_tab"))
        }
        setLoadState(true)
    }, [])

    return (
        <div className='container bg-light shadow mt-3 p-3'>
            {(() => {
                if (loadState) {
                    return (
                        <Row>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={defaultAccordionActiveKey}>
                                <Row>
                                    <Col sm={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="overview">Overview</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="sites">Sites</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="roles">Roles</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="user">User</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="patient">Patient</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="case">Case</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="survey">Survey</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="reports">Reports</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="overview">
                                                <Container>
                                                    <Tabs defaultActiveKey="overview" id="uncontrolled-tabs" className="mb-3"
                                                        >
                                                        <Tab eventKey="overview" title="Overview">
                                                            <Container>
                                                                <Row><h3>Overview</h3></Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p>KI Outcomes is a tools to provide basic survey information for patients.</p>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Tab>
                                                        <Tab eventKey="starting" title="Getting Started">
                                                            <Container>
                                                                <Row><h3>Getting Started</h3></Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p>To get started, go to the Settings page by clicking the Settings button in the top navbar. Configure you site logo and name. Then use the bulk upload feature to import your cases, patient, and providers.</p>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Tab>
                                                        <Tab eventKey="bulk" title="Bulk Upload">
                                                            <Container>
                                                                <Row><h3>Bulk Upload</h3></Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p>To get started, go to the Settings page by clicking the Settings button in the top navbar. Configure you site logo and name. Then use the bulk upload feature</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row><h5>Template</h5></Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p>Only Site Admins can create and activate/deactivate site. Deactivated are inaccessible.</p>
                                                                        <p>To create a site, a Site Admins must login and fill out the site form.</p>
                                                                        <Image className="pb-2 w-100" src={API_HOST+"/images/bulk_upload_excel_csv.png"} rounded />
                                                                    </Col>
                                                                </Row>
                                                                <Row><h5>Uploading the Template</h5></Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p>Only Site Admins can create and activate/deactivate site. Deactivated are inaccessible.</p>
                                                                        <p>To create a site, a Site Admins must login and fill out the site form.</p>
                                                                        <Image className="pb-2 w-100" src={API_HOST+"/images/bulk_upload_excel_csv.png"} rounded />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Tab>
                                                    </Tabs>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="roles">
                                                <Container>
                                                    <Row><h3>Roles</h3></Row>
                                                    <Row><p>There are 6 distinct roles each of which provide various </p></Row>
                                                    <Row><h5>Site Admin</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Site Admins are meant to create and manage sites but cannot view any content within a site keeping your patient data secure.</p>
                                                            <ul>
                                                                <li>Can create, activate, and deactivate sites</li>
                                                                <li>Can create users of any level</li>
                                                                <li>Can create users of any level</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Practice Admin</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Practice Admins are meant to manage sites but cannot create or activate/deactivate sites. They can also view any content within a site.</p>
                                                            <ul>
                                                                <li>Can access practice</li>
                                                                <li>Can change site configuration</li>
                                                                <li>Can access all reports</li>
                                                                <li>Can create, update, and delete users of thier role or below current role</li>
                                                                <li>Can create, update, and delete patients</li>
                                                                <li>Can create, update, and delete cases</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Manger</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Mangers are meant primarily for analysis but can also enter data within a site.</p>
                                                            <ul>
                                                                <li>Can access practice</li>
                                                                <li>Can change site configuration</li>
                                                                <li>Can access some reports</li>
                                                                <li>Can create, update, and delete users of thier role or below current role</li>
                                                                <li>Can create, update, and delete patients</li>
                                                                <li>Can create, update, and delete cases</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Office</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>The Office role is meant for site management and can create patients and cases but not aceess any reports or create users within a site.</p>
                                                            <ul>
                                                                <li>Can access practice</li>
                                                                <li>Can create, update, and delete patients</li>
                                                                <li>Can create, update, and delete cases</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Providers</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <ul>
                                                                <li>Can access practice</li>
                                                                <li>Ki Report, Patient and Case reports but is restricted to viewing cases and patients relevant to self</li>
                                                                <li>Can view patients and cases</li>
                                                                <li>Can be added as provider(s) to cases</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Patient Login</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>The Patient Login role is meant for your kiosk. It is how patients can login and is the only role that will allow this or access to the survey form for a site.</p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="sites">
                                                <Container>
                                                    <Row><h3>Sites</h3></Row>
                                                    <Row><p>Sites are specific to locations and provide access to reports, surveys</p></Row>
                                                    <Row><h5>Create</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Only Site Admins can create and activate/deactivate site. Deactivated are inaccessible.</p>
                                                            <p>To create a site, a Site Admins must login and fill out the site form.</p>
                                                            <Image className="pb-2" src={API_HOST+"/images/create_site.png"} rounded />
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Configuration</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Once a site is created, either a Site Admin or a Practice Admin can modify the configuration. By going to the Settings tab in the top navbar, the site name and logo can be updated but the site slug will remain the same.</p>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Bulk Upload</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Once a site is created, either a Site Admin or a Practice Admin can modify the configuration. By going to the Settings tab in the top navbar, the site name and logo can be updated but the site slug will remain the same.</p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="survey">
                                                <Container>
                                                    <Row><h3>Survey</h3></Row>
                                                    <Row><p></p></Row>
                                                    <Row><h5>Access</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>The Patient Login role is the only role that has access to the login </p>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Cadence</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>The fir</p>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Configure Cases</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p></p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="user">
                                                <Container>
                                                    <Row><h3>Users</h3></Row>
                                                    <Row><p>User are staff members and store authentication and authorization data.</p></Row>
                                                    <Row><h5>Create</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Users can be created by Site Admins, Practice Admins, and Managers and can be created with the same role of the user currently logged in or any role below them.</p>
                                                            <p>To create a user you need a name, email, role, and site. If you are creating a user within a site, the current site is set as the user's site</p>

                                                            <p>Upon</p>
                                                        </Col>
                                                    </Row>
                                                    <Row><h5>Password Set and Reset</h5></Row>
                                                    <Row>
                                                        <Col>
                                                            <p></p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="patient">
                                                <Container>
                                                    <Row><h3>Patients</h3></Row>
                                                    <Row>
                                                        <Col>
                                                            <p></p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="case">
                                                <Container>
                                                    <Row><h3>Cases</h3></Row>
                                                    <Row>
                                                        <Col>
                                                            <p></p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="reports">
                                                <Container>
                                                    <Row><h3>Reports</h3></Row>
                                                    <Row><p>KI Outcomes employs Tableau to build out reporting for your case and patients and provides a set of prebuilt reports to allow quick and easy analysis.</p></Row>
                                                </Container>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Row>
                    )
                }
            })()}
            <FooterComponent />
        </div>
    );
}

export default Help
