import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import axios from 'axios';
import Alert from "react-bootstrap/Alert";
import notificationContext from '../../../context/NotificationContext';

import { AuthContext } from '../../../helper/AuthContext'

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../../components/nav/LoginPracticeNavbarComponent';

function LoginPatient() {
    const API_HOST = process.env.REACT_APP_API_HOST
    let { practice } = useParams();
    const { setAuthState } = useContext(AuthContext)
    const { showAlert } = useContext(notificationContext)
    const navigate = useNavigate()
    const [practiceName, setPracticeName] = useState('Outcomes Made Easy')
    const [patient, setPatient] = useState()
    const [privacy, setPrivacy] = useState(true)
    const [loadState, setLoadState] = useState(false)

    const [form, setForm] = useState({
        email: null,
    })

    const [alert, setAlert] = useState(null);

    const updateForm = (e) => {
        //console.log(e.target.name + ": " + e.target.value)
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setAlert(null)
        var emailPhone = form.email.trim()
        if (!emailPhone.includes("@")) {
            emailPhone = emailPhone.replace(/\D/g, '').trim();
        }
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: `${API_HOST}/patients/${practice}/${emailPhone}`,
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        };

        axios.request(config).then(res => {
            if (res.data.success) {
                if (res.data.data) {
                    //setPatient(res.data.data)
                    navigate(`/practice/${practice}/checkin/survey/${res.data.data.id}`)
                    //navigate(`/practice/${practice}/checkin/survey/${patient.id}`)
                    //console.log(res.data.data)
                    //console.log(`/practice/${practice}/checkin/welcome/${res.data.data.id}`)
                    //navigate(`/practice/${practice}/checkin/welcome/${res.data.data.id}`)
                } else {
                    showAlert({
                        variant: 'danger',
                        message: 'No patient found using this email.'
                    });
                }
            }
            if (res.data.alert) {
                showAlert({
                    variant: res.data.variant,
                    message: res.data.message
                });
            }

        }).catch(error => {
            console.log("")
            showAlert({
                variant: "danger",
                //message: JSON.stringify(error)
                message: "An unknown error has occured."
            })
        })
    }

    const privacyChange = () => {
        console.log("privacyChange")
        setPrivacy(true)
    }

    const privacyPolicyRoute = () => {
        navigate(`/privacy-policy`)
    }

    const routeChange = () => {
        navigate(`/practice/${practice}/checkin/survey/${patient.id}`)
    }

    useEffect(() => {
        axios.get(`${API_HOST}/practices/${practice}`, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    if (res.data.data) {
                        console.log("res.data.data.name" + res.data.data.name)
                        setPracticeName(res.data.data.name)
                    }
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    });
                }
                setLoadState(true)
            })
            .catch(error => {
                setLoadState(true)
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })

    }, [])

    return (
        <>
            <div id='checkin-welcome-page' className='bg-light staff-page-container'>
                <NavbarComponent practice={practice} />
                {(() => {
                    if (loadState) {
                        return (
                            <div className="container p-3">
                                {(() => {
                                    if (patient) {
                                        if (privacy) {
                                            return (
                                                <div className="container p-3">
                                                    <div className="container p-3">
                                                        <div className="row">
                                                            <h1 className='text-primary text-center'>Please Complete The Survey On The Following Screen</h1>
                                                        </div>
                                                        <div className="row p-3">
                                                            <div className="container p-3">
                                                                <div className="card shadow">
                                                                    <form className="container p-3 card-body" onSubmit={routeChange}>
                                                                        <div className="row">
                                                                            <p className="pt-2">If you would like to view our privacy policy please refer <a className='link-primary' onClick={privacyPolicyRoute}>click here</a>.</p>
                                                                        </div>
                                                                        {/* 
                                                                        <div className="row p-3">
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                    I agree and consent
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        */}
                                                                        <div className="row pt-3">
                                                                            <div className="col-12 p-3">
                                                                                <button type='submit' className="w-100 btn btn-primary">Continue</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="container p-3">
                                                    <div className="container p-3">
                                                        <div className="row">
                                                            <h1 className='text-primary text-center'>Welcome {patient.name}</h1>
                                                        </div>
                                                        <div className="row p-3">
                                                            <div className="container p-3">
                                                                <div className="card shadow">
                                                                    <div className="container p-3 card-body">
                                                                        <div className="row">
                                                                            <p className="pt-2">We are committed to helping you make progress and attain your rehabilitation goals.</p>
                                                                            <p className="pt-2">Please take a moment to answer the questions on the following screen.</p>
                                                                            <p className="pt-2">This information is helpful to track your progress and adjust your treatment plan as needed.</p>
                                                                        </div>
                                                                        <div className="row pt-3">
                                                                            <div className="col-12 p-3">
                                                                                <button className="w-100 btn btn-primary" onClick={privacyChange}>Continue</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return (
                                            <div className="container p-3">
                                                
                                                <div className="row p-3">
                                                    <div className="container p-3">
                                                        <div className="container position-absolute top-50 start-50 translate-middle">
                                                            <div className="row d-flex justify-content-center">
                                                                <div>
                                                                    <h1 className='display-4 fw-bold text-primary text-center pt-0'>PLEASE SIGN IN</h1>
                                                                    <h3 className='text-primary text-center pt-0'>So We Can Track Your Progress</h3>
                                                                </div>
                                                            </div>
                                                            <div className="row d-flex justify-content-center">
                                                                <div className="card dynamic-card-resize-patient-login shadow-lg" style={{"max-width": "550px"}}>                                                            
                                                                    <div className="container p-3 card-body">
                                                                        <form onSubmit={onSubmit} className="container p-3">
                                                                            <div className="form-group row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="mb-4 form-floating">  
                                                                                        <input id="staff-email-login" autoFocus className="custom-focus-ring form-control form-control-lg shadow" type="text" name="email" placeholder='Enter your phone number' onChange={updateForm} required />
                                                                                        <label htmlFor="staff-email-login">Enter your phone number</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <button type="submit" className="w-100 btn btn-primary btn-lg" style={{"fontSize": "20px"}}>Continue</button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row d-flex justify-content-center">
                                                                <p className="pt-4 text-primary text-center"><span className='user-select-none pe-none'>By continuing to the next screen you agree to our </span><a className='link-primary pointer-on-hover' onClick={privacyPolicyRoute}><u>Privacy Policy</u></a>.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}

                            </div>
                        )
                    }
                })()}

            </div>
        </>

    );
}

export default LoginPatient
