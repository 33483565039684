import React from 'react';
import { useParams } from "react-router-dom"

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import CaseCardComponent from '../../components/components/CaseCardComponent';

import FooterComponent from '../../components/nav/FooterComponent';

function Case() {
    let { practice } = useParams();

    return (
        <div>
            <div className='container'>
                <CaseCardComponent />
            </div>
            <FooterComponent />
        </div>

    );
}

export default Case
