import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import notificationContext from '../../context/NotificationContext';

import CreateSiteModalComponent from './CreateSiteModalComponent';

import axios from 'axios'

function SiteCardComponent(practice) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const {showAlert} = useContext(notificationContext)
  const [data, setData] = useState()
  const [loadState, setLoadState] = useState(false)

  const updateTable = (data) => {
    setData(data)
  };

  useEffect(() => {
    axios.get(`${API_HOST}/practices/${practice.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data)
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
              variant: res.data.variant,
              message: res.data.message
          })
        }
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <>
              <div className="card p-3 mb-5 mt-3">
                <div className="container p-3 card-body">

                  <h5 className="card-title">{data.name}</h5>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Created: </span>{data.createdAt.split('T')[0]}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Updated: </span>{data.updatedAt.split('T')[0]}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Practice: </span>{data.name}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Slug: </span>{data.slug}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Logo: </span><img
                        src={API_HOST + "/images/logos/" + data.logo}
                        className="d-inline-block align-top nav-bar-logo-custom"
                        alt="Logo"
                      /></span>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 p-2">

                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-6 p-2 d-flex">
                      <CreateSiteModalComponent practice={practice.practice} updateTable={updateTable} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      })()}
    </>
  )
}

export default SiteCardComponent