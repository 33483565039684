import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { Outlet, Navigate } from 'react-router'
import { AuthContext } from '../helper/AuthContext'

function SiteAdminRoutes() {

  const navigate = useNavigate()
  const { authState } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [authorized, setAuthorized] = useState()

  useEffect(() => {
    //Get auths here
    if (authState) {
      if (authState.Role) {
        if (authState.Role.level === 5) {
          setAuthorized(true)
          setLoading(true)
        } else {
          navigate("/403")
        }
      }
    }
  }, [authState])


  return (
    <>
      {(() => {
        if (loading) {
          return (
            authorized ? <Outlet /> : <Navigate to="/403" />
          )
        }
      })()}
    </>
  )
}

export default SiteAdminRoutes