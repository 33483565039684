import React, { useState } from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import SiteCardComponent from '../../components/components/SiteCardComponent';
import BulkUploadCardComponent from '../../components/components/BulkUploadCardComponent';


import FooterComponent from '../../components/nav/FooterComponent';

function Settings() {
    let { practice } = useParams();
    return (
        <div className='container bg-light shadow mt-3 p-3'>
            <Tabs
                defaultActiveKey="site"
                id="fill-tab-site"
                className="mb-3"
                fill
            >
                <Tab eventKey="site" title="Site" className='w-100'>
                    <SiteCardComponent practice={practice}/>
                </Tab>
                <Tab eventKey="bulk-upload" title="Bulk Upload" className='w-100'>
                    <BulkUploadCardComponent practice={practice}/>
                </Tab>
            </Tabs>
            <FooterComponent />
        </div>

    );
}

export default Settings
