import React from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../../components/nav/LoginPracticeNavbarComponent';

import WelcomeComponent from '../../../components/components/WelcomeComponent';
import SurveyComponent from '../../../components/components/SurveyComponent';


function Survey() {
    let { practice } = useParams();

    return (
        <>
            <div id='checkin-survey-page' className='grey-background-100'>
            <NavbarComponent practice={practice} />
                <div className='container'>
                    <SurveyComponent />
                </div>
            </div>
        </>

    );
}

export default Survey
