import React, { useState } from "react";
import { NotificationProvider } from "./NotificationContext";

import Alert from "react-bootstrap/Alert";

const Notification = ({ children }) => {
    const [showAlert, toggleAlert] = useState(false)
    const [alert, setAlert] = useState({})
    const [fadeProp, setFadeProp] = useState('fade-in')
    
    return (
        <NotificationProvider value={{
            showAlert: (data) => {
                toggleAlert(true)
                setFadeProp('fade-in')
                setAlert(data)
                setTimeout(() => {
                    setFadeProp('fade-out')
                    new Promise(resolve => setTimeout(resolve, 1500)).then(()=> {
                        toggleAlert(false)
                        setAlert({})  
                    })      
                }, 5000)
            }
        }}>
            {children}
            {showAlert && 
                <div className="alertify-logs" style={{ position: "fixed", top: 15, right: 15, width: '25vw' }}>
                <Alert variant={alert.variant} dismissible  className={fadeProp}>
                    <Alert.Heading>
                        {alert.message}
                    </Alert.Heading>
                </Alert>
            </div>
            }
        </NotificationProvider>
    )
}

export default Notification;