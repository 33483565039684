import React, { useState } from 'react';

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/SiteAdminNavbarComponent';

import WelcomeComponent from '../../components/components/WelcomeComponent';
//import PatientsTableComponent from '../components/components/PatientsTableComponent';
import UsersTableComponent from '../../components/components/UsersAdminTableComponent';
import SiteTableComponent from '../../components/admin/SiteTableComponent';

import Accordion from 'react-bootstrap/Accordion';

import FooterComponent from '../../components/nav/FooterComponent';

function SiteAdmin() {

    const [defaultAccordionActiveKey, setDefaultAccordionActiveKey] = useState()


    return (
        <div className='staff-page-container'>
            <NavbarComponent />
            <div className='container'>
                <WelcomeComponent />
                <Accordion defaultActiveKey={defaultAccordionActiveKey}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h2>Sites</h2></Accordion.Header>
                        <Accordion.Body>
                            <SiteTableComponent />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h2>Users</h2></Accordion.Header>
                        <Accordion.Body>
                            <UsersTableComponent />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <FooterComponent />
        </div>
    );
}

export default SiteAdmin
