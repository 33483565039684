import React, { useState } from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/SiteAdminNavbarComponent';
import UserCardComponent from '../../components/components/AdminUserCardComponent';
import FooterComponent from '../../components/nav/FooterComponent';

function Settings() {
    return (
        <div className='staff-page-container'>
            <NavbarComponent />
            <div className='container'>
                <UserCardComponent />
            </div>
            <FooterComponent />
        </div>

    );
}

export default Settings
