import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from '../../helper/AuthContext'
import RangeSlider from 'react-bootstrap-range-slider';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import axios from 'axios'

function SurveyComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const STATUS = process.env.REACT_APP_STATUS
  const PATTERN_BASED = process.env.REACT_APP_PATTERN_BASED.trim().toLowerCase()
  const navigate = useNavigate()
  let { practice, userid } = useParams();
  const { authState } = useContext(AuthContext)
  const [loadState, setLoadState] = useState(false)
  //First then every 28
  var q1DatedaydiffCheck = 28 // PROD
  
  //Not first then every 14
  var q2DatedaydiffCheck = 14 // PROD
  
  //Not first then 14 then every 28 
  var q3DatedaydiffFirstCheck = 14 // PROD
  var q3DatedaydiffCheck = 28 // PROD

  if (STATUS == "DEV") {
    q1DatedaydiffCheck = 0 // TESTING
    q2DatedaydiffCheck = 0 // TESTING
    q3DatedaydiffFirstCheck = 0 // TESTING
    q3DatedaydiffCheck = 0 // TESTING
  }
  const [practiceName, setPracticeName] = useState('this practice')
  const [questionText, setQuestionText] = useState({
    question_1: {
      text_1: "How would you rate your problem area today on a 0-100% scale?",
      text_2: "100% being normal (movement, speed, strength, power, endurance, and function), and 0% being unable to move.",
    },
    question_2: "Over the past two weeks, how has your condition changed?",
    question_3: "How satisfied are you with your overall experience at {practiceName}?",
  });

  const [patient, setPatient] = useState({})

  const [form, setForm] = useState({
    question_1: null,
    question_2: null,
    question_3: null,
    show_question_1: false,
    show_question_2: false,
    show_question_3: false,
    first_visit_flag: false,
    survey_flag: false,
    PracticeId: practice,
    CaseId: null,
    PatientId: userid,
    last_visit_on_case: false,
  })

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const updateTextSliderForm = (e) => {
    setForm({
      ...form,
      question_2: e.target.value,
    })
  }

  useEffect(() => {
    var CaseId = null
    var show_question_1 = false
    var show_question_2 = false
    var show_question_3 = false 
    var question_1 = null
    var question_2 = null
    var question_3 = null
    var first_visit_flag = false
    var survey_flag = true
    var dichargeDate = null
    var last_visit_on_case = false
    var today = new Date()
    today.setHours(0, 0, 0)
    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: `${API_HOST}/cases/practice/${practice}/active/${userid}`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
    };
    axios.get(`${API_HOST}/practices/${practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          if (res.data.data) {
            setPracticeName(res.data.data.name)
          }
        }
      })
    axios.request(config).then(res => {
      if (res.data.success && res.data.data) {
        show_question_1 = res.data.data.fsr
        show_question_2 = res.data.data.grb
        show_question_3 = res.data.data.nps
        CaseId = res.data.data.id
        var defaultDate = new Date(res.data.data.evaluationDate)
        dichargeDate = new Date(res.data.data.dischargeDate)
        dichargeDate.setHours(0, 0, 0)
        var diff = dichargeDate.getTime() - today.getTime();
        var daydiff = Math.round(diff / (1000 * 60 * 60 * 24))
        if (daydiff === 0) {
          last_visit_on_case = true
          config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: `${API_HOST}/visits/practice/${practice}/case/${res.data.data.id}/last-visit-surveyed`,
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
          }
          axios.request(config).then(res => {
            if (res.data.success) {
              if (res.data.data.count === 0 ) { 
                if (show_question_1) {
                  question_1 = 50
                }
                if (show_question_2) {
                  question_2 = 3
                }
                if (show_question_3) {
                  question_3 = 5
                }
                if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                  survey_flag = false
                }
                if (show_question_1 || show_question_2 || show_question_3) {
                  setForm({
                    ...form,
                    first_visit_flag: first_visit_flag,
                    survey_flag: survey_flag,
                    show_question_1: show_question_1,
                    show_question_2: show_question_2,
                    show_question_3: show_question_3,
                    question_1: question_1,
                    question_2: question_2,
                    question_3: question_3,
                    CaseId: CaseId,
                    last_visit_on_case: last_visit_on_case,
                  })
                  let config = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: `${API_HOST}/patients/practice/${practice}/${userid}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                  };
    
                  axios.request(config).then(res => {
                    setPatient(res.data)
                    setLoadState(true)
                  })
                } else {
                  axios.post(`${API_HOST}/visits/practice/${practice}`, {
                    question_1: null,
                    question_2: null,
                    question_3: null,
                    show_question_1: false,
                    show_question_2: false,
                    show_question_3: false,
                    first_visit_flag: first_visit_flag,
                    survey_flag: true,
                    PracticeId: practice,
                    CaseId: CaseId,
                    PatientId: userid,
                  }, { withCredentials: true })
                  .then(res => {
                      navigate(`/practice/${practice}/checkin/thank-you`)
                  })
                }
              } else {
                //PENDING CODING
                //CHECK WHAT HAS ALREADY BEEN SERVED
                var todaysDate = new Date();
                todaysDate.setHours(0,0,0,0);
                if (res.data.data.visits.fsr_visit) {
                  if (res.data.data.visits.fsr_visit.createdAt) {
                    var q1Date = new Date(res.data.data.visits.fsr_visit.createdAt)
                    q1Date.setHours(0,0,0,0)
                    if(q1Date.toISOString() == todaysDate.toISOString()) {
                      show_question_1 = false
                    }
                  }
                }
                if (res.data.data.visits.grb_visit) {
                  if (res.data.data.visits.grb_visit.createdAt) {
                    var q2Date = new Date(res.data.data.visits.grb_visit.createdAt)
                    q2Date.setHours(0,0,0,0)
                    if(q2Date.toISOString() == todaysDate.toISOString()) {
                      show_question_2 = false
                    }
                  }
                }
                if (res.data.data.visits.nps_visit) {
                  if (res.data.data.visits.nps_visit.createdAt) {
                    var q3Date = new Date(res.data.data.visits.nps_visit.createdAt)
                    q3Date.setHours(0,0,0,0)
                    if(q3Date.toISOString() == todaysDate.toISOString()) {
                      question_3 = false
                    }
                  }     
                } 
                if (show_question_1) {
                  question_1 = 50
                }
                if (show_question_2) {
                  question_2 = 3
                }
                if (show_question_3) {
                  question_3 = 5
                }
                if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                  survey_flag = false
                }
                if (show_question_1 || show_question_2 || show_question_3) {
                  setForm({
                    ...form,
                    first_visit_flag: first_visit_flag,
                    survey_flag: survey_flag,
                    show_question_1: show_question_1,
                    show_question_2: show_question_2,
                    show_question_3: show_question_3,
                    question_1: question_1,
                    question_2: question_2,
                    question_3: question_3,
                    CaseId: CaseId,
                    last_visit_on_case: last_visit_on_case,
                  })
                  let config = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: `${API_HOST}/patients/practice/${practice}/${userid}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                  };
    
                  axios.request(config).then(res => {
                    setPatient(res.data)
                    setLoadState(true)
                  })
                }  else {
                  axios.post(`${API_HOST}/visits/practice/${practice}`, {
                    question_1: null,
                    question_2: null,
                    question_3: null,
                    show_question_1: false,
                    show_question_2: false,
                    show_question_3: false,
                    first_visit_flag: first_visit_flag,
                    survey_flag: true,
                    PracticeId: practice,
                    CaseId: CaseId,
                    PatientId: userid,
                  }, { withCredentials: true })
                  .then(res => {
                      navigate(`/practice/${practice}/checkin/thank-you`)
                  })
                }
              }
            } else {
              axios.post(`${API_HOST}/visits/practice/${practice}`, {
                question_1: null,
                question_2: null,
                question_3: null,
                show_question_1: false,
                show_question_2: false,
                show_question_3: false,
                first_visit_flag: false,
                survey_flag: false,
                PracticeId: practice,
                CaseId: CaseId,
                PatientId: userid,
                last_visit_on_case: last_visit_on_case,
              }, { withCredentials: true })
                .then(res => {
                    navigate(`/practice/${practice}/checkin/thank-you`)
                })
            }
          })
        } else {
          config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: `${API_HOST}/visits/practice/${practice}/case/${res.data.data.id}/last-visit-surveyed`,
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
          }
          axios.request(config).then(res => {
            if (res.data.success) {
              if (res.data.data.count === 0 ) { 
                first_visit_flag = true
                show_question_2 = false
                show_question_3 = false 
              } else {
                if (PATTERN_BASED == 'true') {
                  if (res.data.data.visits.previous_visit) {
                    if (show_question_1) {
                      if (res.data.data.visits.previous_visit.survey_fsr_flag) {
                        show_question_1 = false
                      }
                    }
                    if (show_question_3) {
                      if (res.data.data.visits.previous_visit.survey_nps_flag) {
                        show_question_3 = false
                      }
                    }
                    if (show_question_1) {
                      question_1 = 50
                    }
                    if (show_question_2) {
                      question_2 = 3
                    }
                    if (show_question_3) {
                      question_3 = 5
                    }
                    if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                      survey_flag = false
                    }
                    if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                      survey_flag = false
                    }
                  } else {
                    show_question_2 = false
                    show_question_3 = false
                    if (show_question_1) {
                      question_1 = 50
                    }
                    if (show_question_2) {
                      question_2 = 3
                    }
                    if (show_question_3) {
                      question_3 = 5
                    }
                    if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                      survey_flag = false
                    }
                  }
                } else {
                  if (show_question_1) {
                    var q1Date = new Date(res.data.data.visits.fsr_visit.createdAt)
                    if (!res.data.data.visits.fsr_visit) {
                      q1Date = new Date(defaultDate)
                    } else {
                      defaultDate = new Date(res.data.data.visits.fsr_visit.createdAt)
                    }
                    q1Date.setHours(0, 0, 0)
                    var q1Datediff = today.getTime() - q1Date.getTime();
                    var q1Datedaydiff = Math.round(q1Datediff / (1000 * 60 * 60 * 24))
                    show_question_1 = (q1Datedaydiff >= q1DatedaydiffCheck)
                  }
                  if (show_question_2) {
                    var q2Date = new Date(res.data.data.createdAt)
                    if (!res.data.data.visits.grb_visit) {
                      q2Date = new Date(defaultDate)
                    } else  {
                      q2Date = new Date(res.data.data.visits.grb_visit.createdAt)
                    }
                    q2Date.setHours(0, 0, 0)
                    var q2Datediff =  today.getTime()- q2Date.getTime();
                    var q2Datedaydiff = Math.round(q2Datediff / (1000 * 60 * 60 * 24))
                    show_question_2 = (q2Datedaydiff >= q2DatedaydiffCheck)
                  }
                  if (show_question_3) {
                    //DETERMINE IF QUESTION SHOULD BE DISPLAYED
                    var q3Date = new Date(res.data.data.createdAt)                  
                    var q3DatedaydiffCheckInternal = q3DatedaydiffCheck
                    if (!res.data.data.visits.nps_visit) {
                      q3Date = new Date(defaultDate)
                      q3DatedaydiffCheckInternal = q3DatedaydiffFirstCheck
                    } else  {
                      q3Date = new Date(res.data.data.visits.nps_visit.createdAt)
                    }
                    q3Date.setHours(0, 0, 0)
                    var q3Datediff = today.getTime() - q3Date.getTime();
                    var q3Datedaydiff = Math.round(q3Datediff / (1000 * 60 * 60 * 24))
                    show_question_3 = (q3Datedaydiff >= q3DatedaydiffCheckInternal)
                  }
                }
              }
              if (show_question_1) {
                question_1 = 50
              }
              if (show_question_2) {
                question_2 = 3
              }
              if (show_question_3) {
                question_3 = 5
              }
              
              if (!(show_question_1 || show_question_2 || show_question_3) && !first_visit_flag) {
                survey_flag = false
              }
              setForm({
                ...form,
                first_visit_flag: first_visit_flag,
                survey_flag: survey_flag,
                show_question_1: show_question_1,
                show_question_2: show_question_2,
                show_question_3: show_question_3,
                question_1: question_1,
                question_2: question_2,
                question_3: question_3,
                CaseId: CaseId,
                last_visit_on_case: last_visit_on_case,
              })
              if (show_question_1 || show_question_2 || show_question_3) {
                let config = {
                  method: 'GET',
                  maxBodyLength: Infinity,
                  url: `${API_HOST}/patients/practice/${practice}/${userid}`,
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  withCredentials: true,
                };
  
                axios.request(config).then(res => {
                  setPatient(res.data)
                  setLoadState(true)
                })
                
              } else {
                axios.post(`${API_HOST}/visits/practice/${practice}`, {
                  question_1: null,
                  question_2: null,
                  question_3: null,
                  show_question_1: false,
                  show_question_2: false,
                  show_question_3: false,
                  first_visit_flag: first_visit_flag,
                  survey_flag: true,
                  PracticeId: practice,
                  CaseId: CaseId,
                  PatientId: userid,
                  last_visit_on_case: last_visit_on_case,
                }, { withCredentials: true })
                .then(res => {
                  navigate(`/practice/${practice}/checkin/thank-you`)
                })
              }
            } else {
              axios.post(`${API_HOST}/visits/practice/${practice}`, {
                question_1: null,
                question_2: null,
                question_3: null,
                show_question_1: false,
                show_question_2: false,
                show_question_3: false,
                first_visit_flag: false,
                survey_flag: false,
                PracticeId: practice,
                CaseId: CaseId,
                PatientId: userid,
                last_visit_on_case: last_visit_on_case,
              }, { withCredentials: true })
                .then(res => {
                    navigate(`/practice/${practice}/checkin/thank-you`)
                })
              //POST VISIT AND REDIRECT TO THANK YOU
            }
          })
        }
      } else {
        navigate(`/practice/${practice}/checkin/thank-you`)
      }
    })
  }, [])

  const onSubmit = (event) => {
    event.preventDefault();
    axios.post(`${API_HOST}/visits/practice/${practice}`, form, { withCredentials: true })
      .then(res => {
          navigate(`/practice/${practice}/checkin/thank-you`)
      })
  }



  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container shadow p-3 mb-4 bg-white rounded mt-3'>
              <div className='row'>
                <Form className='col-12' onSubmit={onSubmit}>
                  <div className='row'>
                    <h1 className='fw-bold text-center text-dark'>Hello {patient.data.first_name}</h1>
                  </div>
                  {(() => {
                    if (form.show_question_1) {
                      return (
                        <Form.Group as={Row} className="pt-3">
                          <div className='col-12'>
                            <div className='row pt-5 pb-2 text-center'>
                              <h3 className='text-center fw-bold text-dark'>{questionText.question_1.text_1}</h3>
                              {/* <h5 className='text-center fw-bold'>{questionText.question_1.text_2}</h5> */} 
                            </div>
                            <div className='row p-3'>
                              <div className='col-12'>
                              <div className="row pb-5">
                                  <div className="col-4">

                                  </div>
                                  <div className="col-4 pt-3 d-flex justify-content-center">
                                    <div className="input-group" style={{width: '120px'}}>
                                      <input className="form-control text-dark fw-bold input-box-bigger text-center" name="question_1" inputMode='numeric' type="number" pattern='[0-9]+' steps={1} min="0" max="100" value={form.question_1} onChange={updateForm} />
                                      <span className="input-group-text text-dark fw-bold input-box-bigger">%</span>
                                    </div>
                                  </div>
                                  <div className="col-4">

                                  </div>
                                </div>
                                <div className='row'>
                                  <div className="range">
                                  <div className="ticks">
                                      <span className="tick text-center"><p className='pt-4 lh-1 bigger-font-size last-tick text-nowrap text-dark'>Unable<br/>To Move<br/>0%</p></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">10%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">20%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">30%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">40%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">50%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">60%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">70%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">80%</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage text-dark">90%</span></span>
                                      <span className="tick text-center"><p className='pt-4 lh-1 bigger-font-size last-tick text-dark'>Normal Function<br/>100%</p></span>
                                    </div>
                                    <input name="question_1" type="range" step={1} max={100} min={0} value={form.question_1} onChange={updateForm} />
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      )
                    }
                  })()}
                  {(() => {
                    if (form.show_question_2) {
                      return (
                        <Form.Group as={Row}>
                          <div className='col-12'>
                            <div className='row p-5 text-center'>
                              <h3 className='fw-bold text-center text-dark'>{questionText.question_2}</h3>
                            </div>
                            <div className='row p-3'>
                              <div className='col-12'>
                                <div className='row'>
                                  <div className="range pb-2">
                                    <div className="ticks">
                                      <span className="tick-multiple-lines pt-1"><span className="pt-2 two-line-text text-dark">Much Worse</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 one-line-text text-dark">Worse</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 three-line-text text-dark">A Little Worse</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 two-line-text text-dark">No Change</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 three-line-text text-dark">A Little Better</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 one-line-text text-dark">Better</span></span>
                                      <span className="tick-multiple-lines"><span className="pt-2 two-line-text text-dark">Much Better</span></span>
                                    </div>
                                    <input type="range" name="question_2" step="1" max="6" min="0" value={form.question_2} onChange={updateTextSliderForm} />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>

                            </div>
                          </div>
                        </Form.Group>
                      )
                    }
                  })()}


                  {(() => {
                    if (form.show_question_3) {
                      return (
                        <Form.Group as={Row} className=''>
                          <div className='col-12'>
                            <div className='row px-5 pt-5 text-center'>
                              <h3 className='fw-bold text-center pb-4 text-dark'>How satisfied are you with your overall experience at {practiceName}?</h3>
                            </div>
                            {/* 
                            <div className='row p-3 pb-5'>
                              <div className='col-12'>
                                <div className='row'>
                                  <div className="range">
                                  <div className="ticks">
                                      <span className="tick text-center"><p className='pt-4 lh-1 bigger-font-size first-tick'>Not At All Likely<br/>0</p></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">1</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">2</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">3</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">4</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">5</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">6</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">7</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">8</span></span>
                                      <span className="tick"><span className="pt-2 tick-percentage">9</span></span>
                                      <span className="tick text-center"><p className='pt-4 lh-1 bigger-font-size last-tick'>Extremely Likely<br/>10</p></span>
                                    </div>
                                    <input type="range" name="question_3" step="1" max={10} min={0} value={form.question_3} onChange={updateForm} />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            */}
                            <div className='row p-3 pb-5'>
                              <div className='col-12'>
                                <div className='row'>
                                  <div class="col">
                                    <div className="text-center">
                                      <h5 className='radio-button-font-size text-dark'>Very Dissatisfied</h5>
                                      <div className="form-check">
                                        <input class="form-check-input radio-button-center" type="radio" value={0} name="question_3" onChange={updateForm}/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div className="text-center">
                                      <h5 className='radio-button-font-size text-dark'>Dissatisfied</h5>
                                      <div className="form-check">
                                        <input class="form-check-input radio-button-center" type="radio" value={2} name="question_3" onChange={updateForm}/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div className="text-center">
                                      <h5 className='radio-button-font-size text-dark'>Neutral</h5>
                                      <div className="form-check">
                                        <input class="form-check-input radio-button-center" type="radio" value={5} name="question_3" onChange={updateForm} defaultChecked/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div className="text-center">
                                      <h5 className='radio-button-font-size text-dark'>Satisfied</h5>
                                      <div className="form-check">
                                        <input class="form-check-input radio-button-center" type="radio" value={8} name="question_3" onChange={updateForm}/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div className="text-center">
                                      <h5 className='radio-button-font-size text-dark'>Very Satisfied</h5>
                                      <div className="form-check">
                                        <input class="form-check-input radio-button-center" type="radio" value={10} name="question_3" onChange={updateForm}/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>



                        </Form.Group>
                      )
                    }
                  })()}

                  <div className="row">
                    <div className="col-12 p-3">
                      <button type='submit' className="w-100 btn btn-primary btn-lg" style={{"fontSize": "20px"}}>Submit</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default SurveyComponent