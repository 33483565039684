import React, { useContext, useState, useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom"

import { AuthContext } from '../../helper/AuthContext'

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import WelcomeComponent from '../../components/components/WelcomeComponent';
import CaseTableComponent from '../../components/components/CaseTableComponent';
import PatientsTableComponent from '../../components/components/PatientsTableComponent';
import UsersTableComponent from '../../components/components/UsersTableComponent';

import Accordion from 'react-bootstrap/Accordion';

import FooterComponent from '../../components/nav/FooterComponent';

function Home() {
    let { practice } = useParams();
    const { authState } = useContext(AuthContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultAccordionActiveKey, setDefaultAccordionActiveKey] = useState()
    const [loadState, setLoadState] = useState(false)


    useEffect(() => {
        console.log(searchParams.get("accordion_tab"))
        if (searchParams.get("accordion_tab")) {
            setDefaultAccordionActiveKey(searchParams.get("accordion_tab"))
        }
        setLoadState(true)
    }, [])

    return (
        <div className='container'>
            <div className='container'>

                <WelcomeComponent />
                {(() => {
                    if (loadState) {
                        return (
                            <Accordion defaultActiveKey={defaultAccordionActiveKey}>
                                {(() => {
                                    if (authState.Role.level > 0) {
                                        return (
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><h2>Patients</h2></Accordion.Header>
                                                <Accordion.Body>
                                                    <PatientsTableComponent practice={practice} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (authState.Role.level > 0) {
                                        return (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><h2>Cases</h2></Accordion.Header>
                                                <Accordion.Body>
                                                    <CaseTableComponent />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (authState.Role.level > 2) {
                                        return (
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header><h2>Users</h2></Accordion.Header>
                                                <Accordion.Body>
                                                    <UsersTableComponent practice={practice} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }
                                })()}
                            </Accordion>
                        )
                    }
                })()}
            </div>
            <FooterComponent />
        </div>

    );
}

export default Home
