import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Outlet, Navigate } from 'react-router'
import { AuthContext } from '../helper/AuthContext'

import axios from 'axios'

function PracticeLoginRoutes() {
  let { practice } = useParams();
  const { authState } = useContext(AuthContext)
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()


  const [loading, setLoading] = useState(false)
  const [authorized, setAuthorized] = useState()

  useEffect(() => {
    console.log("PRACTICE")
    if (authState) {
      axios.get(`${API_HOST}/practices/${practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          if (res.data.data) {
            if (!res.data.data.active) {
              navigate("/practice-not-active")
            } else {
              if (authState.Role.level === 0 && authState.PracticeId == practice) {
                setAuthorized(true)
                setLoading(true)
              }  else {
                navigate("/403")
              }
            }
          }
        } else {
          navigate("/403")
        }
      }).catch(error => {
        //navigate("/500")
      })
    }
  }, [authState])


return (
  <>
    {(() => {
      if (loading) {
        return (
          authorized ? <Outlet /> : <Navigate to="/403" />
        )
      }
    })()}
  </>
)
}

export default PracticeLoginRoutes