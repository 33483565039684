import React, { useContext, useEffect } from 'react'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { AuthContext } from '../../helper/AuthContext'

function NavbarBasicComponent() {
  const host = process.env.REACT_APP_DEV_HOST
  const { authState, setAuthState } = useContext(AuthContext)

  useEffect(() => {
  }, [])

  return (
    <Navbar bg="light" expand="lg" className="navbar-light">
      <div className="container">
        <Navbar.Brand href="/">
          <img
            src="/logo.png"
            className="d-inline-block align-top nav-bar-logo-custom"
            alt="Logo"
          />
        </Navbar.Brand>
      </div>
    </Navbar>
  )
}

export default NavbarBasicComponent