import React, { useMemo, useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import notificationContext from '../../context/NotificationContext';
import { AuthContext } from '../../helper/AuthContext'

import CreatePatientModalComponent from './CreatePatientModalComponent';

import axios from 'axios'

function PatientsTableComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { showAlert } = useContext(notificationContext)
  let { practice } = useParams();
  const navigate = useNavigate()
  const { authState } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [loadState, setLoadState] = useState(false)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15, //set different default page size by initializing the state here
  });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        filterVariant: 'text',
      }, {
        accessorKey: "gender",
        header: "Gender",
        filterVariant: 'text',
        Cell: ({ cell }) => {
          return (
            <div className='text-capitalize'>
              <span style={{ color: 'green' }}>● </span>
              {cell.row.original.gender}
            </div>
          )
        }
      }, {
        accessorKey: "email",
        header: "Phone/Email",
        filterVariant: 'text',
      }, {
        accessorKey: "dob",
        header: "DOB",
        filterVariant: 'date-range',
      }, {
        accessorKey: "active",
        header: "Active",
        filterVariant: 'text',
        Cell: ({ cell }) => {
          if (cell.row.original.active) {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'green' }}>● </span>
                Active
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'red' }}>● </span>
                Inactive
              </div>
            )
          }
        }
      }
    ]
  )

  const updateTable = (res) => {
    const flattenedData = {
      "id": res.id,
      "name": res.name,
      "first_name": res.first_name,
      "last_name": res.last_name,
      "gender": res.gender,
      "email": res.email,
      "dob": new Date((String(res.dob))).toDateString(),
      "createdAt": new Date((String(res.createdAt))).toDateString(),
      "updatedAt": new Date((String(res.updatedAt))).toDateString(),
      "active": res.active,
    }
    setData(current => [flattenedData, ...current])
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd', //instead of the default "onChange" mode
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/practice/${practice}/patients/${row.original.id}`)
        //console.info(JSON.stringify(row.original.id));
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    state: { pagination, rowSelection, sorting }, //must pass states back down if using their on[StateName]Change callbacks
  });

  useEffect(() => {
    axios.get(`${API_HOST}/patients/practice/${practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          res.data.data.forEach(element => {
            const flattenedData = {
              "id": element.id,
              "name": element.name,
              "first_name": element.first_name,
              "last_name": element.last_name,
              "gender": element.gender,
              "email": element.email,
              "dob": new Date((String(element.dob))).toDateString(),
              "createdAt": new Date((String(element.createdAt))).toDateString(),
              "updatedAt": new Date((String(element.updatedAt))).toDateString(),
              "active": element.active,
            }
            setData(current => [flattenedData, ...current])
          })

          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      }).catch(error => {
        showAlert({
          variant: "danger",
          message: "An unknown error has occured."
        })
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container shadow p-3 mb-5 bg-white rounded mt-3'>
              <div className='row justify-content-between'>
                <span className='d-flex justify-content-between'>
                  <h2>Patients</h2>
                  {(() => {
                    if (authState.Role.level > 1) {
                      return (
                        <CreatePatientModalComponent updateTable={updateTable} practice={practice} />
                      )
                    }
                  })()}
                </span>
              </div>
              <div className='row m-2'>
                <hr />
              </div>

              <div className='row'>
                <div className='col-12 overflow-scroll'>
                  <MaterialReactTable table={table} />
                </div>
              </div>
              {/* <div className='row pt-5'><CSVLink data={data} filename={"patients_"+new Date().toISOString()+".csv"}>Download</CSVLink></div> */}
            </div>
          )
        }
      })()}
    </>
  )
}

export default PatientsTableComponent