import React, { useContext, useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { useNavigate } from "react-router-dom"
import { CSVLink } from "react-csv";
import notificationContext from '../../context/NotificationContext';

import CreateSiteModalComponent from './CreateSiteModalComponent';
import CreateUserModalComponent from './CreateUserModalComponent';

import axios from 'axios'

function SiteTableComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const {showAlert} = useContext(notificationContext)
  const [data, setData] = useState([])
  const [loadState, setLoadState] = useState(false)

  const updateTable = (data) => {
    if (!data.email) {
      const flattenedData = {
        "name": data.name,
        "slug": data.slug,
        "active": data.active,
        "createdAt": new Date((String(data.createdAt))).toDateString(),
        "updatedAt": new Date((String(data.updatedAt))).toDateString(),
      }
      setData(current => [flattenedData, ...current])
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      navigate(`/admin/site/${row.slug}`)
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Practice",
      sort: true,
    }, {
      dataField: "active",
      text: "Active",
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return(
            <div className='text-nowrap'>
              <span style={{ color: 'green' }}>● </span>
              Active
            </div>
          )
        } else {
          return(
            <div className='text-nowrap'>
              <span style={{ color: 'red' }}>● </span>
              Inactive
            </div>
          )
        }
      }
    }, {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    }, {
      dataField: "updatedAt",
      text: "Updated At",
      sort: true,
    }
  ]

  useEffect(() => {
    axios.get(`${API_HOST}/practices`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          res.data.data.forEach(element => {
            const flattenedData = {
              "name": element.name,
              "slug": element.slug,
              "active": element.active,
              "createdAt": new Date((String(element.createdAt))).toDateString(),
              "updatedAt": new Date((String(element.updatedAt))).toDateString(),
            }
            setData(current => [flattenedData, ...current])
          })
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
              variant: res.data.variant,
              message: res.data.message
          })
        }
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container shadow p-3 mb-5 bg-white rounded mt-3'>
              <div className='row'>
                <div className='col-4'>
                  <h1>
                    Practices
                  </h1>
                </div>
                <div className='col-4'>
                  <div className="d-flex justify-content-center">
                    <CreateUserModalComponent />
                  </div>
                </div>
                <div className='col-4'>
                  <div className="d-flex justify-content-end">
                    <CreateSiteModalComponent updateTable={updateTable} />
                  </div>
                </div>
              </div>
              <div className='row m-2'>
                <hr />
              </div>

              <div className='row'>
                <div className='col-12 overflow-scroll'>
                  <BootstrapTable keyField='slug' data={data} columns={columns} rowEvents={rowEvents} striped hover condensed />
                </div>
              </div>
              <div className='row'>
                <CSVLink data={data} filename={"sites_"+new Date().toISOString()+".csv"}>Download</CSVLink>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default SiteTableComponent