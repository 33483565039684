import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button'
import notificationContext from '../../context/NotificationContext';

import CreateCaseModalComponent from './CreateCaseModalComponent';
import DeleteConfirmationModalComponent from './DeleteConfirmationModalComponent';

import { AuthContext } from '../../helper/AuthContext'

import axios from 'axios'

function CaseCardComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  let { practice, case_id } = useParams();
  const { authState } = useContext(AuthContext)
  const { showAlert } = useContext(notificationContext)
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [loadState, setLoadState] = useState(false)

  const updateTable = (res) => {
    setData(res)
  }

  useEffect(() => {
    axios.get(`${API_HOST}/cases/practice/${practice}/${case_id}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data)
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      })
      .catch(error => {
        showAlert({
          variant: "danger",
          message: "An unknown error has occured. Please try again."
        })
      })

  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className="card p-3 mb-5 mt-3 shadow">
              <div className="container p-3 card-body">
                <div className='pb-3'>
                  <Button variant="primary" href={'/practice/' + practice + '/home?accordion_tab=1'}>
                    Back
                  </Button>
                </div>
                <h5 className="card-title pb-2">
                  <span>Case: {data.case_nbr}</span>
                  <span>
                    <Button variant="secondary float-end" href={'/practice/' + practice + '/reports/case?caseid=' + case_id}>
                      Report
                    </Button>
                  </span>
                </h5>
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Created</span>
                    <span className="row">{new Date((String(data.createdAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Updated</span>
                    <span className="row">{new Date((String(data.updatedAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Evaluation</span>
                    {(() => {
                      if (data.evaluationDate) {
                        return (
                          <span className="row">{new Date((String(data.evaluationDate))).toDateString()}</span>
                        )
                      }
                    })()}
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Discharge</span>
                    {(() => {
                      if (data.dischargeDate) {
                        return (
                          <span className="row">{new Date((String(data.dischargeDate))).toDateString()}</span>
                        )
                      }
                    })()}
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Status</span>
                    {(() => {
                      if (data.active) {
                        return (
                          <div className="row">
                            <span className="ps-0">
                              <span style={{ color: 'green' }}>● </span>
                              Active
                            </span>
                          </div>
                        )
                      } else {
                        return (
                          <div className="row">
                            <span className="ps-0">
                              <span style={{ color: 'red' }}>● </span>
                              Inactive
                            </span>
                          </div>
                        )
                      }
                    })()}
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Treatment Goal</span>
                    <span className="row">{data.treatmentGoal}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Postoperative</span>
                    {(() => {
                      if (data.postoperative) {
                        return (
                          <span className="row">
                            Yes
                          </span>
                        )
                      } else {
                        return (
                          <span className="row">
                            No
                          </span>
                        )
                      }
                    })()}
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Patient</span>
                    <a className="row" href={`/practice/${practice}/patients/${data.Patient.id}`}>{data.Patient.name}</a>
                  </div>
                  <div className="col-6 p-2">
                    <span className=" row fw-bold">Patient Phone/Email</span>
                    <span className="row">{data.Patient.email}</span>
                  </div>
                
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Region</span>
                      {data.CasesBodyRegions.map(regions => {
                        return (
                          <span className="row text-uppercase" key={regions.id}>{regions.BodyRegion.label}</span>
                        )

                      })
                      }
                  </div>
                  <div className="col-6 p-2">
                    <span className="row fw-bold">Provider</span>
                      {data.CasesTherapists.map(therapists => {
                        return (
                          <div className="row" key={therapists.id}>{therapists.name}</div>
                        )
                      })
                      }
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-4 p-2">
                    <p>
                      <span className="fw-bold">SAS: </span>
                      {(() => {
                        if (data.fsr) {
                          return (
                            <>
                              Yes
                            </>
                          )
                        } else {
                          return (
                            <>
                              No
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                  <div className="col-4 p-2">
                    <p>
                      <span className="fw-bold">ROC: </span>
                      {(() => {
                        if (data.grb) {
                          return (
                            <>
                              Yes
                            </>
                          )
                        } else {
                          return (
                            <>
                              No
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                  <div className="col-4 p-2">
                    <p>
                      <span className="fw-bold">NPS: </span>
                      {(() => {
                        if (data.nps) {
                          return (
                            <>
                              Yes
                            </>
                          )
                        } else {
                          return (
                            <>
                              No
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                </div>
                {(() => {
                  if (authState.Role.level > 1) {
                    return (
                      <div className="row pt-3">
                        <div className="p-2 d-flex justify-content-end">
                          <CreateCaseModalComponent id={data.id} updateTable={updateTable} />
                          <DeleteConfirmationModalComponent id={data.id} objectText={"Case"} object={"cases"} objectName={data.case_nbr} />
                        </div>
                      </div>
                    )
                  }
                })()}
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default CaseCardComponent