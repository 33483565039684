import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button'
import notificationContext from '../../context/NotificationContext';

import CreateSiteModalComponent from './CreateSiteModalComponent';
import UsersTableComponent from '../components/UsersSiteAdminTableComponent';

import DeleteConfirmationModalComponent from './DeleteConfirmationModalComponent';

import Accordion from 'react-bootstrap/Accordion';

import axios from 'axios'

function SiteCardComponent(practice) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const {showAlert} = useContext(notificationContext)
  const [data, setData] = useState()
  const [loadState, setLoadState] = useState(false)
  const [defaultAccordionActiveKey, setDefaultAccordionActiveKey] = useState()

  const handleDelete = () => {  
    axios.delete(`${API_HOST}/practices/delete/${practice.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          navigate(`/admin/`)
        }
        if (res.data.alert) {
          showAlert({
              variant: res.data.variant,
              message: res.data.message
          })
        }
      })
  };

  const updateTable = (data) => {
    setData(data)
  };

  useEffect(() => {
    axios.get(`${API_HOST}/practices/${practice.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data)
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
              variant: res.data.variant,
              message: res.data.message
          })
        }
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <>
              <div className="card p-3 mb-5 mt-3">
                <div className="container p-3 card-body">

                  <h5 className="card-title">{data.name}</h5>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Created: </span>{data.createdAt.split('T')[0]}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Updated: </span>{data.updatedAt.split('T')[0]}</span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Practice: </span>{data.name}</span>
                    </div>
                    {/* 
                      <div className="col-6 p-2">
                        <span><span className="fw-bold">Slug: </span>{data.slug}</span>
                      </div>
                    */}
                    <div className="col-6 p-2">
                      <span>
                        <span className="fw-bold">Active: </span>
                        {(() => {
                          if (data.active) {
                            return (
                              <>
                                <span style={{ color: 'green' }}>● </span>
                                Active
                              </>
                            )
                          } else {
                            return (
                              <>
                                <span style={{ color: 'red' }}>● </span>
                                Inactive
                              </>
                            )
                          }
                        })()}
                      </span>
                    </div>
                    <div className="col-6 p-2">
                      <span><span className="fw-bold">Logo: </span><img
                        src={API_HOST + "/images/logos/" + data.logo}
                        className="d-inline-block align-top nav-bar-logo-custom"
                        alt="Logo"
                      /></span>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 p-2">

                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-6 p-2 d-flex">
                      <CreateSiteModalComponent practice={practice.practice} updateTable={updateTable} />
                    </div>
                    <div className="col-6 p-2">
                      <DeleteConfirmationModalComponent id={data.slug} objectText={"Practice"} object={"practices"} objectName={data.name} />
                      {/* 
                        <Button variant="danger" onClick={handleDelete} className="float-end">
                          Delete
                        </Button>
                      */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='container'>
                <Accordion defaultActiveKey={defaultAccordionActiveKey}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><h2>Users</h2></Accordion.Header>
                    <Accordion.Body>
                      <UsersTableComponent practice={practice.practice} updateTable={updateTable} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </>
          )
        }
      })()}
    </>
  )
}

export default SiteCardComponent