import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import axios from 'axios';

//import SEOComponent from '../components/SEOComponent';

import NavbarBasicComponent from '../components/nav/NavbarBasicComponent';

import SetPasswordComponent from '../components/login/SetPasswordComponent';

import FooterComponent from '../components/nav/FooterComponent';

function SetPassword() {
    const API_HOST = process.env.REACT_APP_API_HOST
    let { token } = useParams();

    const [loadState, setLoadState] = useState(false)
    const [tokenData, setTokenData] = useState(false)

    useEffect(() => {
        if (token !== null && token !== '') {
            axios.get(`${API_HOST}/tokens/${token}`).then((res) => {
                setTokenData(res)
                setLoadState(true)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])

    return (
        <>
            <div id='set-password-page' className='bg-light staff-page-container'>
                <NavbarBasicComponent />
                {(() => {
                    if (loadState) {
                        return (
                            <div className="container">
                                <div className="row pt-5">
                                    <div className="container">
                                        <SetPasswordComponent token={tokenData}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })()}

            </div>
        </>

    );
}

export default SetPassword
