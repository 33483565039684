import React from 'react';
import { useParams } from "react-router-dom"

import NavbarComponent from '../../components/nav/SiteAdminNavbarComponent';

import WelcomeComponent from '../../components/components/WelcomeComponent';
import SiteCardComponent from '../../components/admin/SiteCardComponent';
import UsersTableComponent from '../../components/components/UsersTableComponent';

import FooterComponent from '../../components/nav/FooterComponent';

function SiteAdminPractice() {
    let { practice } = useParams();
    return (
        <div className='staff-page-container'>
            <NavbarComponent />
            <div className='container'>
                <SiteCardComponent practice={practice}/>
            </div>
            <FooterComponent />
        </div>

    );
}

export default SiteAdminPractice
