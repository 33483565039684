import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import notificationContext from '../../context/NotificationContext';

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import axios from 'axios'

function BulkUploadCardComponent(props) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const {showAlert} = useContext(notificationContext)
  const [loadState, setLoadState] = useState(false)

  const [fileForm, setFileForm] = useState()
  const [useStrict, setUseStrict] = useState(true)
  const [uploadDisabled, setUploadDisabled] = useState(false)

  const fileSelectedHandler = (e) => {
    setFileForm(e.target.files[0])
  }
  const updateSwitchForm = (e) => {
    setUseStrict(e.target.checked)
  }

  useEffect(() => {
    setLoadState(true)
  }, [])

  const onSubmit = (event) => {
    event.preventDefault();
    setUploadDisabled(true)
    let formData = new FormData();
    formData.append('file', fileForm);
    formData.append('strict', useStrict);
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
        withCredentials: true
    }

    axios.post(`${API_HOST}/bulk/upload/${props.practice}`, formData, config)
        .then(res => {
            if (res.data.success) {

            }
            if (res.data.alert) {
                showAlert({
                    variant: res.data.variant,
                    message: res.data.message
                })
            }
            setUploadDisabled(false)
        })
        .catch(error => {
          console.error(error)
            showAlert({
                variant: "danger",
                message: "An unknown error has occured."
            })
            setUploadDisabled(false)
        })

  }

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <>
              <div className="card p-3 mb-5 mt-3">
                <Form onSubmit={onSubmit} className="container p-3 card-body">

                  <h5 className="card-title">Bulk Upload</h5>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>
                  </div>

                  <Form.Group className='row pt-2'>
                    <h6>Bulk Upload File</h6>
                    <Form.Control id="create-site-logo-input" className="form-control" type="file" name="file" onChange={fileSelectedHandler} accept='.xlsx' required />
                  </Form.Group>
                  <Form.Group className='row p-2'>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="active" role="switch" id="switchActiveSite" checked={useStrict} value={useStrict} onChange={updateSwitchForm} />
                        <label className="form-check-label" htmlFor="switchActiveSite">Use Strict (compares names as well)</label>
                    </div>
                  </Form.Group>
                  <div className="row pt-3">
                  <div className="col-6 p-2 d-flex">
                    <Button variant="success" type='submit' disabled={uploadDisabled}>
                      {
                        uploadDisabled ? <Spinner animation="border" /> : <>Upload</>
                      }                      
                    </Button>
                    </div>
                    <div className="col-6 p-2 d-flex">
                      <a href={`${API_HOST}/template/bulkuploadtemplate.xlsx`}>Download Template</a>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )
        }
      })()}
    </>
  )
}

export default BulkUploadCardComponent