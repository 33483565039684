import React, { useContext } from 'react'

import { AuthContext } from '../../helper/AuthContext'

function FooterComponent () {
    const host = process.env.REACT_APP_DEV_HOST
    const {authState} = useContext(AuthContext)

    return(
      <footer>
      </footer>            
    )
}

export default FooterComponent