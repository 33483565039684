import React, { useMemo, useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import notificationContext from '../../context/NotificationContext';

import CreateUserModalComponent from './CreateUserModalComponent';

import axios from 'axios'

function UsersTableComponent(props) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { showAlert } = useContext(notificationContext)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loadState, setLoadState] = useState(false)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15, //set different default page size by initializing the state here
  });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);

  const updateTable = (data) => {
    const flattenedData = {
      "id": data.id,
      "name": data.name,
      "email": data.email,
      "active": data.active,
      "role": data.Role.name,
      "createdAt": new Date((String(data.createdAt))).toDateString(),
      "updatedAt": new Date((String(data.createdAt))).toDateString(),
    }
    setData(current => [flattenedData, ...current])
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        filterVariant: 'text',
      }, {
        accessorKey: "email",
        header: "Email",
        filterVariant: 'text',
      }, {
        accessorKey: "active",
        header: "Status",
        filterVariant: 'text',
        Cell: ({ cell }) => {
          if (cell.row.original.active) {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'green' }}>● </span>
                Active
              </div>
            )
          } else {
            return (
              <div className='text-nowrap'>
                <span style={{ color: 'red' }}>● </span>
                Inactive
              </div>
            )
          }
        }
      }, {
        accessorKey: "role",
        header: "Role",
        filterVariant: 'text',
      }, {
        accessorKey: "createdAt",
        header: "Created",
        filterVariant: 'date-range',
      }, {
        accessorKey: "updatedAt",
        header: "Updated",
        filterVariant: 'date-range',
      }
    ]
  )

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd', //instead of the default "onChange" mode
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/practice/${props.practice}/users/${row.original.id}`)
        //console.info(JSON.stringify(row.original.id));
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    state: { pagination, rowSelection, sorting }, //must pass states back down if using their on[StateName]Change callbacks
  });

  useEffect(() => {
    axios.get(`${API_HOST}/users/practice/${props.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          res.data.data.forEach(element => {
            const flattenedData = {
              "id": element.id,
              "name": element.name,
              "email": element.email,
              "active": element.active,
              "role": element.Role.name,
              "createdAt": new Date((String(element.createdAt))).toDateString(),
              "updatedAt": new Date((String(element.updatedAt))).toDateString(),
            }
            setData(current => [flattenedData, ...current])
          })
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      }).catch(error => {
        showAlert({
          variant: "danger",
          message: "An unknown error has occured."
        })
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container shadow p-3 mb-5 bg-white rounded mt-3'>
              <div className='row justify-content-between'>
                <span className='d-flex justify-content-between'>
                  <h2>Users</h2>
                  <CreateUserModalComponent updateTable={updateTable} practice={props.practice} />
                </span>
              </div>
              <div className='row m-2'>
                <hr />
              </div>

              <div className='row'>
                <div className='col-12 overflow-scroll'>
                <MaterialReactTable table={table} />
                </div>
              </div>
                {/* <div className='row pt-5'><CSVLink data={data} filename={"users_" + new Date().toISOString() + ".csv"}>Download</CSVLink></div>*/}
            </div>

          )
        }
      })()}
    </>
  )
}

export default UsersTableComponent