import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

import axios from 'axios'

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import FooterComponent from '../../components/nav/FooterComponent';

function ReportClinicalPatientCombo() {
    let { practice } = useParams();
    const API_HOST = process.env.REACT_APP_API_HOST
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(false);

    const options = {
        height: 100,
        width: 100,
        hideTabs: false,
        toolbar: "hidden"
        // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
        // All other vizCreate options are supported here, too
        // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
    };

    useEffect(() => {
        axios.get(`${API_HOST}/users/report/token`, { withCredentials: true })
            .then(res => {
                console.log(res.data)
                if (res.data.success) {
                    setToken(res.data.data)
                    setLoading(true)
                    console.log(JSON.stringify(token))
                }
            })
            .catch(error => {
                console.log("ERROR: " + error)
                setLoading(true)
            })
    }, [])

    return (
        <div className='container shadow p-3 mb-4 bg-white rounded mt-3'>
            <div className='row'>
                <div className='col-12'>
                    {(() => {
                        if (loading) {
                            return (
                                <TableauEmbed 
                                    sourceUrl="https://prod-useast-a.online.tableau.com/t/dataceva/views/SpechtPTV6/ClinicalPatientCombined"
                                    token={token}
                                    hideTabs={false}
                                    toolbar={"hidden"}
                                >
                                    <viz-filter field="Slug" value={practice}/>
                                </TableauEmbed>
                            )
                        }
                    })()}
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default ReportClinicalPatientCombo
