import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom"
import axios from 'axios';

import notificationContext from '../../context/NotificationContext';

function NavbarComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { showAlert } = useContext(notificationContext)
  const navigate = useNavigate()

  const [form, setForm] = useState({
    email: ''
  })

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();

    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: `${API_HOST}/forgot-password/${form.email}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.request(config).then(res => {
      if (res.data.success) {
        navigate("/")
      }
      if (res.data.alert) {
        showAlert({
          variant: res.data.variant,
          message: res.data.message
        })
      }

    })
  }

  return (
    <>
      <div className="card dynamic-card-resize shadow-lg position-absolute top-50 start-50 translate-middle">
        <div className="container p-3 card-body">
          <h5 className="card-title">Enter Email</h5>
          <form onSubmit={onSubmit}>

            <div className="mb-4">
              <input id="email" className="form-control" type="text" name="email" placeholder="Enter Email" onChange={updateForm} required />
            </div>

            <button type="submit" className="w-100 btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default NavbarComponent