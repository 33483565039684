import React, { useContext, useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Select from 'react-select'

import notificationContext from '../../context/NotificationContext';
import axios from 'axios'

function CreateUserModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    const { showAlert } = useContext(notificationContext)
    const [isValid, setValid] = useState(true);
    const [loadState, setLoadState] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log("props", props)
        if (props.userid && props.practice) {
            axios.get(`${API_HOST}/users/practice/${props.practice}/user/${props.userid}`, { withCredentials: true })
                .then(res => {
                    console.log(res)
                    if (res.data.success) {
                        setForm({
                            ...form,
                            id: props.userid,
                            first_name: res.data.data.first_name,
                            last_name: res.data.data.last_name,
                            email: res.data.data.email,
                            role: {
                                "label": res.data.data.Role.name,
                                "value": res.data.data.Role.id,
                                "level": res.data.data.Role.level,
                            },
                            practice: props.practice,
                            active: res.data.data.active,
                        })

                        setShow(true);
                    }
                    if (res.data.alert) {
                        showAlert({
                            variant: res.data.variant,
                            message: res.data.message
                        })
                    }
                }).catch(error => {
                    showAlert({
                        variant: "danger",
                        message: "An unknown error has occured."
                    })
                })
        } else {
            setShow(true);
        }
        axios.get(`${API_HOST}/roles`, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    setRoles([])
                    res.data.data.forEach(element => {
                        const flattenedData = {
                            "label": element.name,
                            "value": element.id,
                            "level": element.level,
                        }
                        if (element.level > 3) {
                            setRoles(current => [flattenedData, ...current])
                        }
                    })
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
            })
            .catch(error => {
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })

    }
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        practice: '',
        active: '',
    })

    const [roles, setRoles] = useState([]);

    const roleChange = (event) => {
        setForm({
            ...form,
            role: event
        })
    }

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateSwitchForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValid(false)
        if (props.userid) {
            axios.post(`${API_HOST}/users/update`, {
                id: form.id,
                first_name: form.first_name.trim(),
                last_name: form.last_name.trim(),
                email: form.email.trim(),
                role: form.role.value,
                role_level: form.role.level,
                practice: props.practice,
                active: form.active,
            }, { withCredentials: true })
                .then(res => {
                    if (res.data.success) {
                        props.updateTable(res.data.data)
                    }
                    
                    if (res.data.alert) {
                        showAlert({
                            variant: res.data.variant,
                            message: res.data.message
                        })
                    }
                    setForm({
                        ...form,
                        first_name: '',
                        last_name: '',
                        email: '',
                        role: '',
                        practice: '',
                        active: '',
                    })
                    setValid(true)
                    setShow(false)
                })
                .catch(error => {
                    setValid(true)
                    setShow(false)
                    showAlert({
                        variant: "danger",
                        message: "An unknown error has occured."
                    })
                })    
        } else {
            axios.post(`${API_HOST}/users/create`, {
                first_name: form.first_name,
                last_name: form.last_name,
                email: form.email,
                role: form.role.value,
                role_level: form.role.level,
                practice: props.practice,
            }, { withCredentials: true })
                .then(res => {
                    console.log(res.data)
                    setShow(false)
                    setValid(true)
                    if (res.data.success) {
                        props.updateTable(res.data.data)
                    }
                    if (res.data.alert) {
                        showAlert({
                            variant: res.data.variant,
                            message: res.data.message
                        })
                    }
                })
                .catch(error => {
                    setShow(false)
                    setValid(true)
                    showAlert({
                        variant: "danger",
                        message: "An unknown error has occured."
                    })
                })
    
        }
        
    }

    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            {(() => {
                                if (props.userid) {
                                    return (
                                        <Button variant="primary" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            Update User
                                        </Button>
                                    )
                                } else {
                                    return (
                                        <Button variant="primary" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            Create User
                                        </Button>
                                    )
                                }
                            })()}
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {(() => {
                                            if (props.userid) {
                                                return (
                                                    <>Update User</>
                                                )
                                            } else {
                                                return (
                                                    <>Create User</>
                                                )
                                            }
                                        })()}
                                    </Modal.Title>
                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-6'>
                                                    <h6>First Name</h6>
                                                    <Form.Control id="create-staff-first-name-input" className="form-control" type="text" name="first_name" value={form.first_name} placeholder="First Name" onChange={updateForm} required />
                                                </Form.Group>
                                                <Form.Group className='col-6'>
                                                    <h6>Last Name</h6>
                                                    <Form.Control id="create-staff-last-name-input" className="form-control" type="text" name="last_name" value={form.last_name} placeholder="Last Name" onChange={updateForm} required />
                                                </Form.Group>
                                            </div>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-12'>
                                                    <h6>Role</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Role"
                                                        closeMenuOnSelect={true}
                                                        options={roles}
                                                        onChange={roleChange}
                                                        defaultValue={form.role}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='row pt-2'>
                                                <Form.Group className='col-12 pt-2'>
                                                    <h6>Email</h6>
                                                    <Form.Control id="create-staff-email-input" className="form-control" type="text" name="email" value={form.email} placeholder="Email" onChange={updateForm} required />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Form.Group className='row p-2'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="switchActive" name="active" value={form.active} onChange={updateSwitchForm} defaultChecked={form.active} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Status</label>
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>

                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        {(() => {
                                            if (props.userid) {
                                                return (
                                                    <Button variant="success" type='submit' disabled={!isValid}>
                                                        Update
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>
                                                        Create
                                                    </Button>
                                                )
                                            }
                                        })()}

                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default CreateUserModalComponent