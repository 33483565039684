import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import { AuthContext } from '../../helper/AuthContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import DatePicker from "react-datepicker";

import notificationContext from '../../context/NotificationContext';
import axios from 'axios'

function DeleteConfirmationModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    let { practice } = useParams();
    const id = props.id
    const objectText = props.objectText
    const object = props.object
    const objectName = props.objectName
    const navigate = useNavigate()
    const { showAlert } = useContext(notificationContext)
    const { authState } = useContext(AuthContext)
    const [loadState, setLoadState] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleDelete = () => {
        var url = `${API_HOST}/${object}/delete/${practice}/${id}`
        if (object == 'users') {
            url = `${API_HOST}/users/delete/${id}`
        }
        axios.delete(url, { withCredentials: true })
        .then(res => {
            if (res.data.success) {
                navigate(`/admin`)
                showAlert({
                    variant: "success",
                    message: `${objectText} ${objectName} has been deleted.`
                })
            }
            if (res.data.alert) {
                showAlert({
                    variant: res.data.variant,
                    message: res.data.message
                })
            }
        })
        .catch(error => {
            showAlert({
                variant: "danger",
                message: "An unknown error has occured. Please try again."
            })
        })
        setShow(false)
    }

    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            <Button className='btn btn-danger ms-3' onClick={handleShow}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash me-2" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                                Delete
                            </Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h3>Are your sure you want to delete this {objectText}?</h3>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Button className='btn btn-danger ms-3' onClick={handleDelete}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash me-2" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                        </svg>
                                        Yes
                                    </Button>
                                    <Button className="float-end" variant="secondary" onClick={handleClose}>
                                        No
                                    </Button>
                                </Modal.Body>
                                <Modal.Footer>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default DeleteConfirmationModalComponent