import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import axios from 'axios';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


import { AuthContext } from '../../helper/AuthContext'

function NavbarComponent(practice) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { authState, setAuthState } = useContext(AuthContext)
  const [practiceLogo, setPracticeLogo] = useState('default.jpg')
  const [loadState, setLoadState] = useState(false)
  const navigate = useNavigate()
  const pathname = window.location.pathname

  const fontStyle = {
    fontSize: '20px'
  };

  const logout = () => {
    axios.post(`${API_HOST}/users/logout`, {}, {
      withCredentials: true,
    })
      .then(res => {
        if (res.data.error) {
          setAuthState(undefined)
        } else {
          setAuthState(undefined)
          navigate("/")
        }
      })
      .catch(error => {
        setAuthState(undefined)
      })
  }

  useEffect(() => {
    axios.get(`${API_HOST}/practices/${practice.practice}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          if (res.data.data) {
            setPracticeLogo(res.data.data.logo)
            setLoadState(true)
          }
        }
      })

  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <Navbar collapseOnSelect bg="light" expand="md" className="navbar-light shadow pb-3">
              <div className="container">
                <Navbar.Brand href="/">
                  <img
                    src={API_HOST + "/images/logos/" + practiceLogo}
                    className="d-inline-block align-top nav-bar-logo-custom"
                    alt="Logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="nav-fill w-100 mb-lg-0 bs-navbar-collapse" activeKey={pathname}>

                    {(() => {
                      if (authState.Role.level != 0) {
                        return (
                          <>
                            <Nav.Item>
                              <Nav.Link className='fw-bold' style={fontStyle} href={"/practice/" + practice.practice + "/home"}>Home</Nav.Link>
                            </Nav.Item>
                            {(() => {
                                if (authState.Role.level != 2) {
                                  return (
                                    <>
                                      <NavDropdown className='fw-bold' style={fontStyle} title="Reports" id="navbarScrollingDropdown">
                                        <NavDropdown.Item href={"/practice/" + practice.practice + "/reports/performance-report"}>Ki Report</NavDropdown.Item>
                                        {(() => {
                                          if (authState.Role.level > 3) {
                                            return (
                                              <>
                                                {/* 
                                                  <NavDropdown.Item href={"/practice/" + practice.practice + "/reports/clinical-patient-combo"}>Clinical Patient Combo</NavDropdown.Item>
                                                  <NavDropdown.Item href={"/practice/" + practice.practice + "/reports/clinical"}>Clinical</NavDropdown.Item>
                                                  <NavDropdown.Item href={"/practice/" + practice.practice + "/reports/practice"}>Practice</NavDropdown.Item>
                                                */}
                                              </>
                                            )
                                          }
                                        })()}
                                      </NavDropdown>
                                    </>
                                  )
                                }
                            })()}
                          </>
                        )
                      }
                    })()}
                    {(() => {
                      if (authState.Role.level >= 4) {
                        return (
                          <Nav.Item>
                            <Nav.Link className='fw-bold' style={fontStyle} href={"/practice/" + practice.practice + "/settings"}>Settings</Nav.Link>
                          </Nav.Item>
                        )
                      }
                    })()}
                    <Nav.Item>
                      <Nav.Link className='fw-bold' style={fontStyle} href={"/practice/" + practice.practice + "/help"}>Help</Nav.Link>
                    </Nav.Item>
                    {(() => {
                      if (!authState) {
                        return (
                          <Nav.Item className="float-end fw-lighter">
                            <Nav.Link href="/login/">Login</Nav.Link>
                          </Nav.Item>
                        )
                      } else {
                        //if (authState.Role.level != 0) {
                        return (
                          <Nav.Item className="float-end fw-lighter">
                            <Nav.Link className='text-end'><button type="button" className="btn btn-primary" onClick={logout}>Logout</button></Nav.Link>
                          </Nav.Item>
                        )
                        //}
                      }
                    })()}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          )
        }
      })()}
    </>
  )
}

export default NavbarComponent