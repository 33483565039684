import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../helper/AuthContext'

function NavbarComponent() {
  const API_HOST = process.env.REACT_APP_API_HOST
  const { authState } = useContext(AuthContext)
  const [loadState, setLoadState] = useState(false)

  const current = new Date();
  const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;

  useEffect(() => {
    setLoadState(true)
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className='container p-3'>
              <div className='row text-end'>
                  <span className='fw-bold'>Hi {authState.name}!</span>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default NavbarComponent