import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button'

import notificationContext from '../../context/NotificationContext';
import { AuthContext } from '../../helper/AuthContext'

import axios from 'axios'
import CreatePatientModalComponent from './CreatePatientModalComponent';
import DeleteConfirmationModalComponent from './DeleteConfirmationModalComponent';

function PatientCardComponent(props) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()
  const { showAlert } = useContext(notificationContext)
  const { authState } = useContext(AuthContext)
  let { practice, patientid } = useParams();
  const [data, setData] = useState()
  const [loadState, setLoadState] = useState(false)


  const updateTable = (res) => {
    setData(res)
  }

  useEffect(() => {
    axios.get(`${API_HOST}/patients/practice/${practice}/${patientid}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data)
          setLoadState(true)
        }
        if (res.data.alert) {
          showAlert({
            variant: res.data.variant,
            message: res.data.message
          })
        }
      }).catch(error => {
        showAlert({
          variant: "danger",
          message: "An unknown error has occured."
        })
      })
  }, [])

  return (
    <>
      {(() => {
        if (loadState) {
          return (
            <div className="card p-3 mb-5 mt-3">
              <div className="container p-3 card-body">
                <div className='pb-3'>
                  <Button variant="primary" href={'/practice/' + practice + '/home?accordion_tab=0'}>
                    Back
                  </Button>
                </div>
                <h5 className="card-title pb-2">
                  <span>{data.name}</span>
                  <span>
                    <Button variant="secondary float-end" href={'/practice/' + practice + '/reports/patient?patientid=' + patientid}>
                      Report
                    </Button>
                  </span>
                </h5>

                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Created: </span>{new Date((String(data.createdAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Updated: </span>{new Date((String(data.updatedAt))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Name: </span>{data.name}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Phone/Email: </span>{data.email}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">DOB: </span>{new Date((String(data.dob))).toDateString()}</span>
                  </div>
                  <div className="col-6 p-2">
                    <span><span className="fw-bold">Gender: </span><span class="text-capitalize">{data.gender}</span></span>
                  </div>
                  <div className="col-6 p-2">
                    <p><span className="fw-bold">Status: </span>
                      {(() => {
                        if (data.active) {
                          return (
                            <>
                              <span style={{ color: 'green' }}>● </span>
                              Active
                            </>
                          )
                        } else {
                          return (
                            <>
                              <span style={{ color: 'red' }}>● </span>
                              Inactive
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                </div>
                {(() => {
                  if (authState.Role.level > 1) {
                    return (
                      <div className="row pt-3">
                        <div className="p-2 d-flex justify-content-end">
                          <CreatePatientModalComponent patientid={patientid} updateTable={updateTable} />
                          <DeleteConfirmationModalComponent id={data.id} objectText={"Patient"} object={"patients"} objectName={data.name} />
                        </div>
                      </div>
                    )
                  }
                })()}
              </div>
            </div>

          )
        }
      })()}
    </>
  )
}

export default PatientCardComponent