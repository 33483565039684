import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import notificationContext from '../../context/NotificationContext';
import axios from 'axios'

function CreateCaseModalComponent(props) {
    const API_HOST = process.env.REACT_APP_API_HOST
    const navigate = useNavigate()
    let { practice, case_id } = useParams();
    const { showAlert } = useContext(notificationContext)
    const [loadState, setLoadState] = useState(false)
    const [patients, setPatients] = useState([])
    const [therapists, setTherapists] = useState([])
    const [bodyRegions, setBodyRegions] = useState([])
    const [isValid, setValid] = useState(true);
    const [form, setForm] = useState({
        id: null,
        caseId: '',
        PatientId: '',
        patient: {},
        treatment_goal: { label: "Rehabilitation", value: "Rehabilitation" },
        Therapists: [],
        defaultValue_Therapists: [],
        BodyRegions: [],
        defaultValue_BodyRegions: [],
        practice: practice,
        dischargeDate: '',
        evaluationDate: '',
        location: '',
        active: true,
        fsr: true,
        grb: true,
        nps: true,
        postoperative: false, 
        postoperative_dropdown: { label: "No", value: false },
        treatmentGoal: 'Rehabilitation',
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        axios.get(`${API_HOST}/patients/practice/${practice}`, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    setPatients([])
                    res.data.data.forEach(element => {
                        const flattenedData = {
                            "label": element.name + " (" + element.email + ")",
                            "value": element.id,
                        }
                        setPatients(current => [flattenedData, ...current])
                    })

                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
                axios.get(`${API_HOST}/users/therapists/${practice}`, { withCredentials: true })
                    .then(res => {
                        if (res.data.success) {
                            setTherapists([])
                            res.data.data.forEach(element => {
                                const flattenedData = {
                                    "label": element.name,
                                    "value": element.id,
                                }
                                setTherapists(current => [flattenedData, ...current])
                            })


                        }
                        if (res.data.alert) {
                            showAlert({
                                variant: res.data.variant,
                                message: res.data.message
                            })
                        }
                        axios.get(`${API_HOST}/bodyregions`, { withCredentials: true })
                            .then(res => {
                                if (res.data.success) {
                                    setBodyRegions([])
                                    res.data.data.forEach(element => {
                                        const flattenedData = {
                                            "label": element.label,
                                            "value": element.value,
                                        }
                                        setBodyRegions(current => [flattenedData, ...current])
                                    })
                                    if (case_id) {
                                        axios.get(`${API_HOST}/cases/practice/${practice}/${case_id}`, { withCredentials: true })
                                            .then(res => {
                                                if (res.data.success) {
                                                    var tempCasesTherapists = [];
                                                    var tempCasesBodyRegions = [];
                                                    res.data.data.CasesTherapists.forEach(element => {
                                                        const flattenedData = {
                                                            "label": element.name,
                                                            "value": element.TherapistId,
                                                        }
                                                        tempCasesTherapists.push(flattenedData)
                                                    })
                                                    res.data.data.CasesBodyRegions.forEach(element => {
                                                        const flattenedData = {
                                                            "label": element.BodyRegion.label,
                                                            "value": element.BodyRegion.value,
                                                        }
                                                        tempCasesBodyRegions.push(flattenedData)
                                                    })
                                                    var dischargeDateTemp = ''
                                                    var evaluationDateTemp = ''
                                                    if (res.data.data.dischargeDate) {
                                                        dischargeDateTemp = new Date(res.data.data.dischargeDate)
                                                    }
                                                    if (res.data.data.evaluationDate) {
                                                        evaluationDateTemp = new Date(res.data.data.evaluationDate)
                                                    }
                                                    var postoperative_dropdown = { label: "No", value: false }
                                                    if (res.data.data.postoperative) {
                                                        postoperative_dropdown = { label: "Yes", value: true }
                                                    }
                                                    setForm({
                                                        ...form,
                                                        id: case_id,
                                                        caseId: res.data.data.case_nbr,
                                                        PatientId: res.data.data.PatientId,
                                                        patient: { "label": res.data.data.Patient.name + " (" + res.data.data.Patient.email + ")", "value": res.data.data.PatientId },
                                                        treatment_goal : { "label": res.data.data.treatmentGoal, "value": res.data.data.treatmentGoal },
                                                        Therapists: tempCasesTherapists,
                                                        BodyRegions: tempCasesBodyRegions,
                                                        practice: res.data.data.PracticeId,
                                                        dischargeDate: dischargeDateTemp,
                                                        evaluationDate: evaluationDateTemp,
                                                        location: res.data.data.LocationId,
                                                        active: res.data.data.active,
                                                        fsr: res.data.data.fsr,
                                                        grb: res.data.data.grb,
                                                        nps: res.data.data.nps,
                                                        postoperative: res.data.data.postoperative, 
                                                        postoperative_dropdown: postoperative_dropdown,
                                                        treatmentGoal: res.data.data.treatmentGoal,
                                                    })
                                                    setShow(true)
                                                }
                                                if (res.data.alert) {
                                                    showAlert({
                                                        variant: res.data.variant,
                                                        message: res.data.message
                                                    })
                                                }
                                            })
                                            .catch(error => {
                                                showAlert({
                                                    variant: "danger",
                                                    message: "An unknown error has occured."
                                                })
                                            })

                                    } else {
                                        setShow(true)
                                    }
                                }
                                if (res.data.alert) {
                                    showAlert({
                                        variant: res.data.variant,
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch(error => {
                        showAlert({
                            variant: "danger",
                            message: "An unknown error has occured."
                        })
                    })
            })
            .catch(error => {
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })
    };
    const postoperative_options = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]
    const treatment_goals = [
        { label: "Rehabilitation", value: "Rehabilitation" },
        { label: "Maintenance", value: "Maintenance" },
        { label: "Wellness", value: "Wellness" },
    ]
    const locations = [
        { label: "Here", value: "here" },
        { label: "There", value: "there" },
        { label: "The other place", value: "other" },
    ]

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateSwitchForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const patientChange = (event) => {
        setForm({
            ...form,
            PatientId: event.value,
            patient: { label: event.label, value: event.value, },
        })
    }

    const postoperativeGoalChange = (event) => {
        setForm({
            ...form,
            postoperative: event.value,
            postoperative_dropdown: { label: event.label, value: event.value, },
        })
    }

    const treatmentGoalChange = (event) => {
        setForm({
            ...form,
            treatmentGoal: event.value,
            treatment_goal: { label: event.label, value: event.value, },
        })
    }

    const locationChange = (event) => {
        setForm({
            ...form,
            gender: event.value
        })
    }

    const bodyRegionsOnChange = (event) => {
        setForm({
            ...form,
            BodyRegions: event
        })
    }

    const therapistsOnChange = (event) => {
        setForm({
            ...form,
            Therapists: event
        })
    }

    const updateDischargeDate = (e) => {
        setForm({
            ...form,
            dischargeDate: e
        })
    }
    const updateEvaluationDateDate = (e) => {
        setForm({
            ...form,
            evaluationDate: e
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValid(false)
        var create_or_update = 'create'
        if (case_id) {
            create_or_update = 'update'
        }

        axios.post(`${API_HOST}/cases/practice/${practice}`, {
            id: form.id,
            caseId: form.caseId.trim(),
            PatientId: form.PatientId,
            Therapists: form.Therapists,
            BodyRegions: form.BodyRegions,
            PracticeId: practice,
            dischargeDate: form.dischargeDate,
            evaluationDate: form.evaluationDate,
            location: form.location,
            active: form.active,
            fsr: form.fsr,
            grb: form.grb,
            nps: form.nps,
            treatmentGoal: form.treatmentGoal,
            postoperative: form.postoperative,
            create_or_update: create_or_update,
        }, { withCredentials: true })
            .then(res => {
                if (res.data.success) {
                    props.updateTable(res.data.data)
                    if (create_or_update == 'create') {
                        navigate(`/practice/${practice}/home`)
                        try {
                            Array.from(document.querySelectorAll('.accordion-header button:not(.collapsed)')).forEach(button=>button.click())
                        } catch(e){}
                        //window.location.reload(false);
                    }
                }
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
                event.target.reset()
                setForm({
                    ...form,
                    caseId: '',
                    PatientId: '',
                    patient: {},
                    treatment_goal: { label: "Rehabilitation", value: "Rehabilitation" },
                    Therapists: [],
                    defaultValue_Therapists: [],
                    BodyRegions: [],
                    defaultValue_BodyRegions: [],
                    practice: practice,
                    dischargeDate: '',
                    evaluationDate: '',
                    location: '',
                    active: true,
                    fsr: true,
                    grb: true,
                    nps: true,
                    postoperative: false, 
                    postoperative_dropdown: { label: "No", value: false },
                    treatmentGoal: 'Rehabilitation',
                })
                setValid(true)
                /*
                if (form.id) {
                    setShow(false)
                }
                */
                setShow(false)
            })
            .catch(error => {
                setShow(false)
                setValid(true)
                showAlert({
                    variant: "danger",
                    message: "An unknown error has occured."
                })
            })
    }

    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            {(() => {
                if (loadState) {
                    return (
                        <>
                            {(() => {
                                if (case_id) {
                                    return (
                                        <Button variant="success" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            EDIT CASE
                                        </Button>
                                    )
                                } else {
                                    return (
                                        <Button variant="primary" onClick={handleShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen me-2" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg> 
                                            CREATE CASE
                                        </Button>
                                    )
                                }
                            })()}


                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    {(() => {
                                        if (case_id) {
                                            return (
                                                <Modal.Title>EDIT CASE</Modal.Title>
                                            )
                                        } else {
                                            return (
                                                <Modal.Title>CREATE CASE</Modal.Title>
                                            )
                                        }
                                    })()}


                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body>
                                        <div className='container'>
                                            {(() => {
                                                if (!case_id) {
                                                    return (
                                                        <div className='row pt-2'>
                                                            <Form.Group className='col-12'>
                                                                <h6>Patient</h6>
                                                                <Select
                                                                    styles={{
                                                                        // Fixes the overlapping problem of the component
                                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                                    }}
                                                                    placeholder="Patient"
                                                                    closeMenuOnSelect={true}
                                                                    value={form.patient}
                                                                    options={patients}
                                                                    onChange={patientChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            {(() => {
                                                if (!case_id) {
                                                    return (
                                                        <div className='row'>
                                                            <div className='col-12 mt-2'>
                                                                <Form.Group>
                                                                    <h6>Case ID</h6>
                                                                    <Form.Control id="case-id-input" className="form-control" type="text" name="caseId" placeholder="Case ID" onChange={updateForm} value={form.caseId} required />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            <div className='row'>
                                                <Form.Group className='col-6 mt-2'>
                                                    <h6>Provider</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Provider(s)"
                                                        closeMenuOnSelect={true}
                                                        options={therapists}
                                                        defaultValue={form.Therapists}
                                                        value={form.Therapists}
                                                        onChange={therapistsOnChange}
                                                        isMulti
                                                    />
                                                </Form.Group>
                                                <Form.Group className='col-6 datePickerCustomFormatting mt-2'>
                                                    <h6>Evaluation Date</h6>
                                                    <DatePicker id="evaluationDatePicker" className="form-control" selected={form.evaluationDate} onChange={updateEvaluationDateDate} />
                                                </Form.Group>
                                                
                                            </div>
                                            <div className='row'>
                                                <Form.Group className='col-6 mt-2'>
                                                    <h6>Region</h6>
                                                    <Select
                                                        id='region-select-multi-drop'
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Body Region(s)"
                                                        closeMenuOnSelect={true}
                                                        options={bodyRegions}
                                                        defaultValue={form.BodyRegions}
                                                        value={form.BodyRegions}
                                                        onChange={bodyRegionsOnChange}
                                                        isMulti
                                                    />
                                                </Form.Group>
                                                <Form.Group className='col-6 mt-2'>
                                                    <h6>Postoperative</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Postoperative"
                                                        closeMenuOnSelect={true}
                                                        value={form.postoperative_dropdown}
                                                        options={postoperative_options}
                                                        onChange={postoperativeGoalChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='row'>
                                                <Form.Group className='col-6 mt-2'>
                                                    <h6>Treatment Goal</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Treatment Goal"
                                                        closeMenuOnSelect={true}
                                                        value={form.treatment_goal}
                                                        options={treatment_goals}
                                                        onChange={treatmentGoalChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group className='col-6 datePickerCustomFormatting mt-2'>
                                                    <h6>Discharge Date</h6>
                                                    <DatePicker id="dischargeDatePicker" className="form-control" selected={form.dischargeDate} onChange={updateDischargeDate} />
                                                </Form.Group>
                                            </div>
                                            {(() => {
                                                if (false) {
                                                    return (
                                                        <Form.Group className='col-6 z-3'>
                                                            <h6>Location</h6>
                                                            <Select
                                                                styles={{
                                                                    // Fixes the overlapping problem of the component
                                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                                }}
                                                                placeholder="location"
                                                                closeMenuOnSelect={true}
                                                                options={locations}
                                                                onChange={locationChange}
                                                            />
                                                        </Form.Group>
                                                    )
                                                }
                                            })()}
                                            {/* 
                                            <div className='row p-2 mt-3'>
                                                <Form.Group className='col-6'>
                                                    <h6>Postoperative</h6>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                        placeholder="Postoperative"
                                                        closeMenuOnSelect={true}
                                                        value={form.postoperative_dropdown}
                                                        options={postoperative_options}
                                                        onChange={postoperativeGoalChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group className="col-6 form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchPostoperative" name="postoperative" value={form.postoperative} onChange={updateSwitchForm} defaultChecked={form.postoperative} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Postoperative</label>
                                                </Form.Group>
                                            </div>
                                            */}
                                            <Form.Group className='row p-2'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchFSR" name="fsr" value={form.fsr} onChange={updateSwitchForm} defaultChecked={form.fsr} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Self Assessment Scale (SAS)</label>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className='row p-2'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchGRB" name="grb" value={form.grb} onChange={updateSwitchForm} defaultChecked={form.grb} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Rating of Change (ROC)</label>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className='row p-2'>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="switchNPS" name="nps" value={form.nps} onChange={updateSwitchForm} defaultChecked={form.nps} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Net Promotor Score (NPS)</label>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                        {(() => {
                                            if (case_id) {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>
                                                        SAVE
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Button variant="primary" type='submit' disabled={!isValid}>
                                                        SAVE
                                                    </Button>
                                                )
                                            }
                                        })()}
                                    </Modal.Footer>
                                </Form>

                            </Modal>
                        </>
                    )
                }
            })()}
        </>
    );
}

export default CreateCaseModalComponent