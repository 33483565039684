import React, { useState } from 'react';
import { useParams } from "react-router-dom"

//import SEOComponent from '../components/SEOComponent';

import NavbarBasicComponent from '../../components/nav/NavbarBasicComponent';

import ForgotPasswordComponent from '../../components/login/ForgotPasswordComponent';

import FooterComponent from '../../components/nav/FooterComponent';

function SetPassword() {
    let { token } = useParams();
    return (
        <>
            <div id='set-password-page' className='bg-light staff-page-container'>
                <NavbarBasicComponent />
                <div className="container">
                    <div className="row">
                        <div className="container pt-5">
                            <ForgotPasswordComponent practice={token}/>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default SetPassword