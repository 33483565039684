import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom"
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

import axios from 'axios'

//import SEOComponent from '../components/SEOComponent';

import NavbarComponent from '../../components/nav/PracticeNavbarComponent';

import FooterComponent from '../../components/nav/FooterComponent';

function ReportPatients() {
    let { practice } = useParams();
    const [searchParams] = useSearchParams();
    const [patientid, setPatientid] = useState(false);
    const [caseid, setCaseid] = useState(false);
    const API_HOST = process.env.REACT_APP_API_HOST
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(false);

    const options = {
        height: 100,
        width: 100,
        hideTabs: false,
        // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
        // All other vizCreate options are supported here, too
        // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
    };

    useEffect(() => {
        if (searchParams.get('patientid')) {
            setPatientid(searchParams.get('patientid'))
        }
        if (searchParams.get('caseid')) {
            setCaseid(searchParams.get('caseid'))
        }
        axios.get(`${API_HOST}/users/report/token`, { withCredentials: true })
            .then(res => {
                console.log(res.data)
                if (res.data.success) {
                    setToken(res.data.data)
                    setLoading(true)
                    console.log(JSON.stringify(token))
                }
            })
            .catch(error => {
                console.log("ERROR: " + error)
                setLoading(true)
            })
    }, [])

    return (
        <div className='container shadow p-3 mb-4 bg-white rounded mt-3'>
            <div className='row'>
                <div className='col-12'>
                    {(() => {
                        if (loading) {
                            return (
                                <TableauEmbed 
                                    sourceUrl="https://prod-useast-a.online.tableau.com/t/dataceva/views/SpechtPTRevampV1/DailyVisits"
                                    token={token}
                                    hideTabs={false}
                                    toolbar={"hidden"}
                                >
                                    <viz-filter field="Slug" value={practice}/>
                                    {(() => {
                                        if (patientid) {
                                            return ( <viz-filter field="id" value={patientid}/>)
                                        }
                                        if (caseid) {
                                            return ( <viz-filter field="id (Cases)" value={caseid}/>)
                                        }
                                    })()}
                                </TableauEmbed>
                            )
                        }
                    })()}
                </div>
            </div>
            <FooterComponent />
        </div>

    );
}

export default ReportPatients
