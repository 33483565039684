import React, { useState } from 'react';
import './errors.css'; // Tell webpack that Button.js uses these styles

//import SEOComponent from '../components/SEOComponent';

function Error404() {

    return (
        <>
            <div id="notfound">
		<div className="notfound">
			<div className="notfound-404">
				<h1>Oops!</h1>
				<h2>404 - The page can't be found</h2>
			</div>
			<a href="/">Return to home page</a>
		</div>
	</div>
        </>

    );
}

export default Error404
