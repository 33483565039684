import React from 'react';
import './PrivacyPolicy.css'
//import SEOComponent from '../components/SEOComponent';

import NavbarBasicComponent from '../../components/nav/NavbarBasicComponent';

function PrivacyPolicy() {

    return (
        <div id='privacy-policy-page' className='bg-light staff-page-container'>
            <NavbarBasicComponent />
            <div className='container'>
                <a href="/" class="btn btn-primary" id="privacy-return-button" role="button">Return to login</a>
                <div className="">
                    <p className="c9"><span className="c1">Ki Outcomes</span></p>
                    <p className="c9"><span className="c0">Application Privacy Notice</span></p>
                    <p className="c9"><span className="c0">Effective Date: December 8, 2023</span></p>
                    <p className="c9 c7"><span className="c6 c10"></span></p>
                    <p className="c2"><span className="c4">This Application Privacy Notice describes the Personal Information Active Ortho, Inc.
                            DBA Ki Outcomes (&ldquo;</span><span className="c6">Ki Outcomes</span><span className="c4">,&rdquo;
                            &ldquo;</span><span className="c6">KO</span><span className="c4">,&rdquo; &ldquo;</span><span
                            className="c6">we</span><span className="c4">,&rdquo; &ldquo;</span><span className="c6">us</span><span
                            className="c4">,&rdquo; or &ldquo;</span><span className="c6">our</span><span className="c4">&rdquo;) collects from
                            users of our Ki Outcomes application (&ldquo;</span><span className="c6">Application</span><span
                            className="c3">&rdquo;). It also describes how and to whom we disclose the Personal Information entered into the
                            Application. </span></p>
                    <p className="c2"><span className="c3">KO will collect and process protected health information only as required or
                            permitted by our business associate agreement and applicable laws, including the Health Insurance
                            Portability and Accountability Act (HIPAA). KO will always maintain reasonable and appropriate security
                            controls to protect the information as required by the HIPAA Security Rule.</span></p>
                    <p className="c2"><span className="c3">If you have questions about privacy or security, please contact the HIPAA Privacy
                            Official in the Privacy Notice published by your healthcare provider or health insurance plan. If you
                            believe that KO has not handled your personal information properly, you may also contact us at
                            admin@kioutcomes.com.</span></p>
                    <p className="c2"><span className="c3">By entering information into the Application, you agree to our collection and
                            disclosure of your Personal Information as outlined below.</span></p>
                    <p className="c2 c7"><span className="c10 c4"></span></p>
                    <p className="c2"><span className="c0">IMPORTANT INFORMATION REGARDING YOUR PERSONAL INFORMATION</span></p>
                    <p className="c2"><span className="c5">OVERVIEW</span></p>
                    <p className="c2"><span className="c3">Ki Outcomes provides healthcare &nbsp;providers with technology used to collect
                            survey results from their patients. Patients may only access the Application through a provider who has
                            subscribed to our Application (&ldquo;Provider&rdquo;). As used in this Application Privacy Notice,
                            reference to &ldquo;Provider&rdquo; means only the applicable Provider from whom you have been given access
                            to the Application. </span></p>
                    <p className="c2"><span className="c3">To use the Application, you will be asked to enter your name and/or phone number.
                            This information is used to confirm that a Provider has added you to their Ki Outcomes database. After
                            confirmation is received, you will be able to access the survey features of the Application. The results of
                            the survey, and any information you enter into the survey, will be shared with the applicable
                            Provider.</span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">COLLECTION</span></p>
                    <p className="c2"><span className="c6">Contact Information</span><span className="c3"><br/>First and last name, email address,
                            and telephone number.</span></p>
                    <p className="c2"><span className="c6">Health-Related Information</span><span className="c3"><br/>Age, gender, and health
                            information related to the treatment or services you receive from the Provider.</span></p>
                    <p className="c2"><span className="c6">Information About Minors</span><span className="c3"><br/>The Application is intended for
                            individuals 16 or older. We do not knowingly collect information from children under 16.</span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">USE </span></p>
                    <p className="c2"><span className="c6">Contact Information</span><span className="c3"><br/>This information is collected when you
                            sign in to the Application and is used to associate you with the information provided to us by the
                            Provider.</span></p>
                    <p className="c2"><span className="c6">Survey Responses</span><span className="c3"><br/>We use your survey responses to generate
                            insights and scores to help the Provider evaluate and improve the services they provide you.</span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">SHARING</span></p>
                    <p className="c2"><span className="c6">Providers</span><span className="c3"><br/>We share your Contact Information and
                            Health-Related Information with the Provider from whom you accessed the Application.</span></p>
                    <p className="c2"><span className="c6">Service Providers </span><span className="c3"><br/>We share Personal Information with our
                            service providers to help us operate our business and who are bound by contractual obligations to keep the
                            information confidential.</span></p>
                    <p className="c2"><span className="c6">Business Transfers</span><span className="c3"><br/>We may share or transfer Personal
                            Information we store in relation to a merger, sale, financing, or acquisition of all or a portion of our
                            business to another person or entity.</span></p>
                    <p className="c2"><span className="c6">As Aggregated Information</span><span className="c3"><br/>We may disclose aggregated
                            information about our users that does not identify any individual to our business partners or any other
                            third party.</span></p>
                    <p className="c2"><span className="c6">Legal Compliance</span><span className="c3"><br/>We may disclose your Personal Information
                            to comply with applicable laws, a court order, or legal processes and to protect our legal rights.</span>
                    </p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">PROTECTION</span></p>
                    <p className="c2"><span className="c6">All Personal Information</span><span className="c3"><br/>We use commercially reasonable
                            technical and administrative safeguards to secure Personal Information and reduce the risk of loss or
                            misuse.</span></p>
                    <p className="c2"><span className="c6">HIPAA</span><span className="c3"><br/>To the extent the Personal Information we collect
                            includes protected health information (PHI) under the Health Insurance Portability and Accountability Act
                            (HIPAA), we will follow the legal requirements for the protection and safeguarding of PHI as required by
                            HIPAA and other federal laws. We follow state privacy laws when they are stricter or more protective of your
                            PHI than federal law.</span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">RETENTION</span></p>
                    <p className="c2"><span className="c6">Providers</span><span className="c3"><br/>We retain your Personal Information at the
                            discretion of the Provider. Except as provided in this Application Privacy Notice, we will not retain
                            Personal Information after it has been removed from our systems by the Provider.</span></p>
                    <p className="c2"><span className="c6">Ki Outcomes </span><span className="c3"><br/>We may retain your Personal Information, even
                            if removed from our systems by the Provider, as reasonably required to comply with our legal obligations,
                            including our legal agreements with Providers, regulatory compliance, and court orders. </span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c3"></span></p>
                    <p className="c2"><span className="c5">CORRECTION AND DELETION</span></p>
                    <p className="c2"><span className="c6">All Personal Information </span><span className="c3"><br/>We store Personal Information
                            entered into our Application on behalf of the Provider. To update or delete the Personal Information that we
                            hold, you must contact the Provider. </span></p>
                    <hr/>
                    <p className="c2 c7"><span className="c5"></span></p>
                    <p className="c2"><span className="c5">CONTACT</span></p>
                    <p className="c2"><span className="c6">Ki Outcomes</span><span className="c3"><br/>If you have any questions about this
                            Application Privacy Notice or our handling of your Personal Information, please contact us at
                            admin@kioutcomes.com.</span></p>
                    <p className="c7 c11"><span className="c12"></span></p>
                </div>
            </div>

        </div>

    );
}

export default PrivacyPolicy
