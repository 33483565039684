import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Alert from "react-bootstrap/Alert";
import axios from 'axios';

//import AlertComponent from '../AlertComponent';
//import { NotificationContext } from '../../helper/NotificationContext'


function NavbarComponent(token) {
  const API_HOST = process.env.REACT_APP_API_HOST
  const navigate = useNavigate()

  const [form, setForm] = useState({
    type: null,
    token: null,
    password1: '',
    password2: '',
  })

  useEffect(()=> {
    if (token.token.data.data) {
      setForm ({
        type: token.token.data.data.type,
        token: token.token.data.data.token,
        password1: '',
        password2: '',
      })
    }
  }, [])

  const [alert, setAlert] = useState(null);

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setAlert(null)
    if (form.password1 === form.password2) {
      let formData = new FormData();
      formData.append('token', form.token);
      formData.append('type', form.type);
      formData.append('password', form.password1);

      let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `${API_HOST}/users/set-password`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: formData
      };
      axios.request(config).then(res => {
        if (res.data.success) {
          navigate("/login/")
        }
        if (res.data.alert) {
          setAlert(res.data);
        }
      }).catch(error => {
        setAlert({
          variant: 'danger',
          message: 'An unknown error has occured. Please try again. If this persists contact an admin.'
        });
      })
      //navigate("/")
    } else {
      setAlert({
        variant: 'danger',
        message: 'Your passwords don\'t match.'
      });
    }

  }

  return (
    <>
      {(() => {
        if (alert) {
          return (
            <div style={{ position: "absolute", top: 15, right: 15, zIndex: 999, width: '25vw' }}>
              <Alert variant={alert.variant} dismissible>
                <Alert.Heading>
                  {alert.message}
                </Alert.Heading>
              </Alert>
            </div>
          )
        }
      })()}
      {(() => {
        if (form.type && form.token) {
          return (
            <>
              <div className="card dynamic-card-resize shadow-lg position-absolute top-50 start-50 translate-middle">
                <div className="container p-3 card-body">
                  {(() => {

                    if (form.type === 'invite') {
                      return (
                        <h5 className="card-title">Set Password</h5>
                      )
                    } else {
                      return (
                        <h5 className="card-title">Reset Password</h5>
                      )
                    }
                  })()}

                  <form onSubmit={onSubmit}>


                    <div className="mb-4">
                      <input id="new-password-1" className="form-control" type="password" name="password1" placeholder="Enter Password" onChange={updateForm} />
                    </div>

                    <div className="mb-4">
                      <input id="new-password-2" className="form-control" type="password" name="password2" placeholder="Re-enter Password" onChange={updateForm} />
                    </div>

                    <button type="submit" className="w-100 btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div className="card dynamic-card-resize shadow-lg position-absolute top-50 start-50 translate-middle">
              <div className="container p-3 card-body">
                <div className="row d-flex flex-row justify-content-center">
                  <h5 className="card-title">The token you provide was invalid.</h5>
                </div>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}

export default NavbarComponent