import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import axios from 'axios';

import { AuthContext } from '../../helper/AuthContext'

//import SEOComponent from '../components/SEOComponent';

import NavbarBasicComponent from '../../components/nav/NavbarBasicComponent';
import notificationContext from '../../context/NotificationContext';

function Login() {

    const navigate = useNavigate()
    const API_HOST = process.env.REACT_APP_API_HOST
    const { authState, setAuthState } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { showAlert } = useContext(notificationContext)


    const [form, setForm] = useState({
        email: null,
        password: null,
    })

    const [alert, setAlert] = useState(null);

    const forgotPasswordRoute = () => {
        navigate(`/forgot-password`)
    }

    const updateForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setAlert(null)
        let formData = new FormData();
        formData.append('email', form.email);
        formData.append('password', form.password);

        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: `${API_HOST}/users/auth`,
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
            data: formData,
        };

        axios.request(config).then(res => {
            if (res.data.success) {
                setAuthState(res.data.data)
                if (res.data.data.Role) {
                    if (res.data.data.Role.level == 5) {
                        navigate("/admin/")
                    } else {
                        if (res.data.data.Role.level !== 0) {
                            navigate(`/practice/${res.data.data.PracticeId}/home`)
                        } else {
                            navigate(`/practice/${res.data.data.PracticeId}/checkin`)
                        }
                    }
                }
            }
            if (res.data.alert) {
                if (res.data.alert) {
                    showAlert({
                        variant: res.data.variant,
                        message: res.data.message
                    })
                }
            }
        }).catch(error => {
            showAlert({
                variant: 'danger',
                message: 'An unknown error has occured. Please try again. If this persists contact an admin.'
            });
        })
    }


    useEffect(() => {
        if (authState) {
            if (authState.Role) {
                if (authState.Role.level == 5) {
                    navigate("/admin/")
                } else {
                    if (authState.Role.level !== 0) {
                        navigate(`/practice/${authState.PracticeId}/home`)
                    } else {
                        navigate(`/practice/${authState.PracticeId}/checkin`)
                    }
                }
            }
        } else {
            axios.post(`${API_HOST}/auth/refresh`, {}, {
                withCredentials: true,
            })
                .then(res => {
                    if (!res.data.error) {
                        if (res.data.success && res.data.data) {
                            setAuthState(res.data.data)
                            if (res.data.data) {
                                if (res.data.data.Role) {
                                    if (res.data.data.Role.level === 5) {
                                        navigate("/admin/")
                                    } else {
                                        if (res.data.data.Role.level !== 0) {
                                            navigate(`/practice/${res.data.data.PracticeId}/home`)
                                        } else {
                                            navigate(`/practice/${res.data.data.PracticeId}/checkin`)
                                        }
                                    }
                                }
                            }
                        }
                        setLoading(true)
                    }
                }).catch(error => {
                    setLoading(true)
                })
        }
    }, [authState])

    return (
        <>
            <div id='login-page' className='bg-light staff-page-container'>
                <NavbarBasicComponent />
                {(() => {
                    if (loading) {
                        return (
                            <div className="container">
                                <div id='login-card' style={{"max-width": "400px"}} className="card dynamic-card-resize shadow-lg position-absolute top-50 start-50 translate-middle ">
                                    <div className="container p-3 card-body">
                                        <form onSubmit={onSubmit}>

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="mb-4 form-floating">
                                                        <input id="staff-email-login" autoFocus className="custom-focus-ring form-control form-control-lg shadow" type="text" name="email" placeholder="Email" onChange={updateForm} required />
                                                        <label htmlFor="staff-email-login pb-1" className='control-label'>Email</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="mb-4 form-floating">
                                                        <input id="staff-password-login" className="custom-focus-ring form-control form-control-lg shadow" type="password" name="password" placeholder="Password" onChange={updateForm} required />
                                                        <label htmlFor="staff-password-login">Password</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="mb-4">
                                                        <a className='link-primary' onClick={forgotPasswordRoute}>Forgot password?</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="w-100 btn btn-primary btn-lg" style={{"font-size": "20px"}}>Log In</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })()}
            </div>
        </>

    );
}

export default Login
