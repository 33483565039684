import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import './errors.css'; // Tell webpack that Button.js uses these styles

//import SEOComponent from '../components/SEOComponent';
import { AuthContext } from '../../helper/AuthContext'

function Error403() {
	const { setAuthState } = useContext(AuthContext)
	const API_HOST = process.env.REACT_APP_API_HOST
	useEffect(() => {
		axios.post(`${API_HOST}/users/logout`, {}, {
			withCredentials: true,
		})
			.then(res => {
				setAuthState(undefined)
			})
			.catch(error => {
				setAuthState(undefined)
			})
	}, [])

	return (
		<>
			<div id="notfound">
				<div className="notfound">
					<div className="notfound-404">
						<h1>Oops!</h1>
						<h2>Practice not active</h2>
					</div>
					<a href="/">Return to home page</a>
				</div>
			</div>
		</>

	);
}

export default Error403
