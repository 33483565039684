import './App.css';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'

import { AuthContext } from './helper/AuthContext'

import {motion as m } from 'framer-motion'

//Auth Pages
import Login from './pages/auth/Login'
import SetPassword from './pages/SetPassword'
import ForgotPassword from './pages/auth/ForgotPassword'
import Landing from './pages/Landing'

//Error Pages
import Error404 from './pages/errors/Error404'
import Error403 from './pages/errors/Error403'
import Error500 from './pages/errors/Error500'
import PracticeNotActive from './pages/errors/PracticeNotActive'

//Patient Pages
import LoginPatient from './pages/practice/patient/LoginPatient'
import Survey from './pages/practice/patient/Survey'
import ThankYouForCheckingIn from './pages/practice/patient/ThankYouForCheckingIn'

//Practice Pages
import Home from './pages/practice/Home'
import Case from './pages/practice/Case'
//Case
import Patient from './pages/practice/Patient'
//Patients
//User
//Users
import User from './pages/practice/User'
import Reports from './pages/practice/Reports'

import ReportClinical from './pages/practice/ReportClinical'
import ReportPatients from './pages/practice/ReportPatients'
import ReportCaseOnly from './pages/practice/ReportCaseOnly'
import ReportPatientOnly from './pages/practice/ReportPatientOnly'
import ReportClinicalPatientCombo from './pages/practice/ReportClinicalPatientCombo'
import ReportPractice from './pages/practice/ReportPractice'

import ReportPerformanceAdmin from './pages/practice/ReportSimple'

//Report
//Report/view
import Help from './pages/practice/Help'
import Settings from './pages/practice/Settings'


//Admin pages
import SiteAdmin from './pages/admin/SiteAdmin'
import SiteAdminPractice from './pages/admin/SiteAdminPractice'
import SiteAdminUser from './pages/admin/User'

import PrivacyPolicy from './pages/legal/PrivacyPolicy'
//import TermsAndConditions from './pages/TermsAndConditions'

// Route Protection
import AuthenticatedRoutes from './route-protection/AuthenticatedRoutes'
import SiteAdminRoutes from './route-protection/SiteAdminRoutes'
import PracticeRoutes from './route-protection/PracticeRoutes'
import PracticeLoginRoutes from './route-protection/PracticeLoginRoutes'
import PracticeSettingsRoutes from './route-protection/PracticeSettingsRoutes'

import NavbarComponent from './components/nav/PracticeNavbarComponent'

function App() {
  let { practice } = useParams();
  const [loadState, setLoadState] = useState(false)
  const [authState, setAuthState] = useState()

  useEffect(() => {
    setLoadState(true)
  }, [])

  return (
    <div className="App">
      <div className="d-flex flex-column min-vh-100 staff-page-container">
        <AuthContext.Provider value={{ authState, setAuthState }}>
          {(() => {
            if (loadState) {
              return (
                <m.div initial={{opacity: -1}} animate={{opacity: 1}} transition={{duration: 2.5}}>
                  <Router>
                    <Routes>
                      <Route exact path="/" element={<Landing />} />
                      <Route exact path="/login" element={<Login />} />
                      <Route exact path="/set-password/:token" element={<SetPassword />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} />

                      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

                      <Route element={<AuthenticatedRoutes />} >
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route exact path='/practice/:practice'>
                          <Route element={<PracticeLoginRoutes />} >
                            <Route exact path="checkin" element={<LoginPatient />} />
                            <Route exact path="checkin/survey/:userid" element={<Survey />} />
                            <Route exact path="checkin/thank-you" element={<ThankYouForCheckingIn />} />
                            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
                          </Route>
                          <Route element={<PracticeRoutes />} >
                            <>
                              <Route exact path="home" element={<Home />} />
                              <Route exact path="cases/:case_id" element={<Case />} />
                              <Route exact path="users/:userid" element={<User />} />
                              <Route exact path="patients/:patientid" element={<Patient />} />
                              <Route exact path="reports" element={<Reports />} />
                              <Route exact path="reports/patients" element={<ReportPatients />} />
                              <Route exact path="reports/performance-report" element={<ReportPerformanceAdmin />} />
                              <Route exact path="reports/patient" element={<ReportPatientOnly />} />
                              <Route exact path="reports/clinical-patient-combo" element={<ReportClinicalPatientCombo />} />
                              <Route exact path="reports/case" element={<ReportCaseOnly />} />
                              <Route exact path="reports/clinical" element={<ReportClinical />} />
                              <Route exact path="reports/practice" element={<ReportPractice />} />
                              <Route element={<PracticeSettingsRoutes />} >
                                <Route exact path="settings" element={<Settings />} />
                              </Route>
                              <Route exact path="help" element={<Help />} />
                            </>
                          </Route>
                        </Route>
                        <Route element={<SiteAdminRoutes />} >
                          <Route exact path="/admin" element={<SiteAdmin />} />
                          <Route exact path="/admin/site/:practice" element={<SiteAdminPractice />} />
                          <Route exact path="/admin/:practice/users/:userid" element={<SiteAdminUser />} />
                        </Route>
                      </Route>
                      <Route exact path="/403" element={<Error403 />} />
                      <Route path="/404" element={<Error404 />} />
                      <Route path="/500" element={<Error500 />} />
                      <Route path="/practice-not-active" element={<PracticeNotActive />} />
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  </Router>
                </m.div>
              )
            }
          })()}
        </AuthContext.Provider>
      </div>
    </div>
  );
}

export default App;
